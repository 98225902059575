import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
// import Modal from "react-bootstrap/Modal";
import {Helmet} from "react-helmet";
import '../Main/lis.css'
import { Modal, Button } from "react-bootstrap";
import "../Main/ipc.css"

export const ListItem = () => {
  
  const [field, setfield] = useState([]);
  const [less, setless] = useState([]);
  const [main_id, setMainid] = useState("");
  const [title, setTitle] = useState("");
  const [descs, setDescs] = useState("");
  const [maintow_id, SetMaintow_id] = useState("");

  const [editData, setEditData] = useState({
    id: null,
    title: "",
    main_id: "",
    maintow_id: "",
  });

  const [isOpen, setIsOpen] = useState(false); // State برای کنترل وضعیت باز/بسته بودن کادر

  const toggleSection = () => {
    setIsOpen(!isOpen); // تغییر وضعیت باز/بسته بودن کادر
  };

  const registerL = () => {
    try {
      if (!title || !main_id) {
        toast.warning("لطفا تمامی موارد خواسته شده را پر نمائید");
      } else {
        axios
          .post("https://drp.academyxray.com/panelAdmin/Mainxlist", {
            title: title,
            descs: descs,
            main_id: main_id,
            maintow_id: maintow_id,
          })
          .then(() => {
            toast.success("ثبت با موفقیت انجام شد");
          })
          .catch((e) => {
            toast.warning("فیلد نام تکراری میباشد");
          });
      }
    } catch (e) {
      toast.error("خطای عملیاتی لطفا با کارشناس سیستم تماس حاصل فرمائید");
    }
  };

  const [filed, setFiled] = useState([]);

    useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMainlist");
      setFiled(await res.clone().json());
    };
    getAllFiled();
  }, [filed]);

  const handleDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDeletelist/${id}`)
      .then((response) => {
        setFiled((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setfield(await res.clone().json());
    };
    getAllFiled();
  }, []);

  const [found, setFound] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/searchItemMainTow");
      setFound(await res.clone().json());
    };
    getAllFiled();
  }, []);


/*   const getItemTree = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainTow/${id}`
      );
      setFound(await res.json());
    } catch (err) {}
  };

  const getItemTreeAll = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/listMainlist2/${id}`
      );
      setFiled(await res.json());
    } catch (err) {}
  }; */

  const updateTwo = async (id) => {
    try {
      if (!editData.title) {
        toast.warning("مقدار عنوان نباید خالی باشد");
      } else {
        await axios.put(`https://drp.academyxray.com/panelAdmin/updateTree/${id}`, {
          title: editData.title,
          main_id: editData.main_id,
          maintow_id: editData.maintow_id,
        });
        toast.success("ویرایش با موفقیت انجام شد");
        handleCloseBR14();
      }
    } catch (err) {
      toast.error("خطا در ویرایش  - لطفا دوباره سعی کنید");
    }
  };

  const [showBR14, setShowBR14] = useState(false);
  const handleCloseBR14 = () => setShowBR14(false);
  const handleShowBR14 = () => setShowBR14(true);

  const openEditModal = (item) => {
    setEditData({
      id: item.id,
      title: item.title,
      main_id: item.main_id,
      maintow_id: item.maintow_id,
    });
    handleShowBR14(); // باز کردن مدال
  };

  const [listPay, setlistPay] = useState([]);
/*   const [filterAllET, setFilterAllET] = useState(listPay);

  const showHandelpopsocial = async (id) => {
    const res = await fetch(
      `https://drp.academyxray.com/panelAdmin/SearchTestHard/${id}`
    );
    setlistPay(await res.clone().json());
    setFilterAllET(await res.json());
    handleShowBR14(true);
  }; */
  const [search,setSearch] = useState('')
  const [menr,setMenr] = useState('')

  const handleMenrChange = (e) => {
    setMenr(e.target.value);
  };
  
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  
  
  return (
    <>
    <Helmet>
      <title>زیر گروه دوم</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku4">
            <h4 className="pok">زیر گروه دوم</h4>
            <div className="card shadow-sm mt-4">
      <div
        className="card-header d-flex justify-content-between align-items-center"
        style={{ cursor: "pointer" }}
        onClick={toggleSection}
      >
        <h5 className="mb-0 pok">ایجاد گروه دوم - جستجو</h5>
        <span
          className="ms-auto"
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: isOpen ? "red" : "green", // تغییر رنگ براساس وضعیت باز/بسته
          }}
        >
          {isOpen ? "-" : "+"} {/* تغییر علامت باز/بسته */}
        </span>
      </div>
      {isOpen && ( // نمایش بخش محتوای کارت اگر باز باشد
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mb-2">
            <div class="col-xs-12 col-sm-3 mt-3">
                    <select 
                      style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setMainid(e.target.value)}
                     
                      name="main_id"
                    >
                      <option value={"23e0w"} className="pok">انتخاب کنید</option>
                      {field.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>
                  <div  class="col-xs-12 col-sm-3 mt-3 pok">
                    <select
                    style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control"
                      onChange={(e) => SetMaintow_id(e.target.value)}
                      name="maintow_id"
                    >
                      <option className="pok" value={"0wss0"}>انتخاب کنید</option>
                      {found.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>

              <div className="col-xs-12 col-sm-3 mt-3 pok">
                <input
                  style={{ borderRadius: "20px" }}
                  type="text"
                  className="form-control"
                  placeholder="عنوان"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
{/* 
              <div className="col-xs-12 col-sm-12 mt-3 pok">
                <textarea
                  style={{ borderRadius: "20px" }}
                  rows={5}
                  className="form-control"
                  placeholder="شرح"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div> */}

              <div className="col-xs-12 col-sm-3 mt-3 pok">
                <button className="btn btn-outline-success" style={{ borderRadius: "20px" }} onClick={registerL}>
                  ایجاد کردن
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <hr />
            <p className="pok">جستجو</p>
            <div className="row mb-2 d-flex align-items-center">
  <div className="col-xs-12 col-sm-3">
  <select 
                      style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setMenr(e.target.value)}
                     
                      name="main_id"
                    >
                      <option value={"23e0w"} className="pok">انتخاب کنید</option>
                      {field.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                       <option value={"all"}>All</option>
          </select>
  </div>

  <p className="pok mt-2 mr-2 mb-0"> جستجو براساس زیر گروه دوم : </p>

  <div className="col-xs-12 col-sm-3">
    <input 
      type="text" 
      style={{ borderRadius: '20px' }}
      className="form-control mt-1 mr-1 pok"
      onChange={(e) => setSearch(e.target.value)} 
    />
  </div>
</div>

      </div>
        </div>
        
      )}

    </div>


              <div class="table-responsive">

                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">گروه اصلی</th>
                      <th className="pok">زیر گروه اول</th>
                      <th className="pok">زیر گروه دوم</th>
                      <th className="pok">نمایش تست</th>
                      <th className="pok">ایجاد تست</th>
                      <th className="pok">ویرایش/حذف</th>
                    </tr>
                  </thead>
                  {filed
                        .filter((item) => {
                          const matchesSearch = search
                            ? item.title.toLowerCase().includes(search.toLowerCase())
                            : true;

                          const matchesMenr =
                            menr === "all" || menr === ""
                              ? true
                              : item.main_id && item.main_id.toLowerCase().includes(menr.toLowerCase());

                          return matchesSearch && matchesMenr;
                        })
                  .map((item, index) => {
                    const { id, main_id, maintow_id, title } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                        <td className="pok">{index + 1}</td>
                        <td className="pok">{item.main_id}</td>
                        <td className="pok">{item.maintow_id}</td>
                        <td className="pok">{item.title}</td>
                        <td className="pok">
                          <div className="difficulty-labelr">
                            <span className="difficulty-textr">آسان</span>
                            <span className="difficulty-textr">متوسط</span>
                            <span className="difficulty-textr">سخت</span>
                          </div>

                          {/* نمایش تصاویر */}
                          <div className="images">
                            <img src="/img/ok.png" height={'25px'} width={'25px'} alt="ok" />
                            <img src="/img/no.png" height={'25px'} width={'25px'} alt="no" />
                            <img src="/img/no.png" height={'25px'} width={'25px'} alt="no" />
                          </div>
                        </td>
                         <td className="pok">
                 <Link className="pok" to='/panelAdmin/Question' state={{ main_id, maintow_id,title }}>تست جدید</Link>
                          </td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => openEditModal(item)}
                              /* onClick={() => updateTwo(id)} */
                            />
                           <img
                              src={del}
                              height="30px"
                              width="30px"
                              className="mr-3"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>

      <Modal show={showBR14} onHide={handleCloseBR14} centered>
        <Modal.Body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th className="pok">ردیف</th>
                  <th className="pok">درجه سوال</th>
                  <th className="pok">وضعیت</th>
                </tr>
              </thead>
            </table>
          </div>
        </Modal.Body>
      </Modal>

 {/* Modal برای ویرایش */}
 <Modal show={showBR14} onHide={handleCloseBR14} centered>
  <Modal.Header closeButton>
    <Modal.Title style={{ fontSize: '22px', fontWeight: 'bold', color: '#333' }} className="pok">ویرایش اطلاعات</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div
      style={{
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        backgroundColor: '#f9f9f9',
        borderRadius: '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
        }}
      >
        <div
          style={{
            flex: '1',
            minWidth: '250px',
            marginBottom: '20px',
          }}
        >
          <input className="pok"
            type="text"
            style={{
              width: '100%',
              padding: '15px',
              fontSize: '18px',
              borderRadius: '10px',
              border: '1px solid #ddd',
              outline: 'none',
              transition: 'border-color 0.3s',
            }}
            value={editData.title}
            onChange={(e) => setEditData({ ...editData, title: e.target.value })}
            placeholder="عنوان"
          />
        </div>
        <div
          style={{
            flex: '1',
            minWidth: '250px',
            marginBottom: '20px',
          }}
        >
          <select className="pok"
            style={{
              width: '100%',
              padding: '15px',
              fontSize: '18px',
              borderRadius: '10px',
              border: '1px solid #ddd',
              outline: 'none',
              transition: 'border-color 0.3s',
            }}
            value={editData.main_id}
            onChange={(e) => setEditData({ ...editData, main_id: e.target.value })}
          >
            <option value="" disabled>
              انتخاب گروه اصلی
            </option>
            {field.map((item) => (
              <option value={item.title} key={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            flex: '1',
            minWidth: '250px',
            marginBottom: '20px',
          }}
        >
          <select  className="pok"
            style={{
              width: '100%',
              padding: '15px',
              fontSize: '18px',
              borderRadius: '10px',
              border: '1px solid #ddd',
              outline: 'none',
              transition: 'border-color 0.3s',
            }}
            value={editData.maintow_id}
            onChange={(e) => setEditData({ ...editData, maintow_id: e.target.value })}
          >
            <option value="" disabled>
              انتخاب زیرگروه
            </option>
            {found.map((item) => (
              <option value={item.title} key={item.id}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer
    style={{
      paddingTop: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
        <Button
        className="pok"
      variant="primary"
      onClick={() => {
        updateTwo(editData.id);
        handleCloseBR14();
      }}
      style={{
        padding: '12px 25px',
        fontSize: '18px',
        borderRadius: '10px',
        backgroundColor: '#007bff',
        border: 'none',
      }}
    >
      ذخیره تغییرات
    </Button>
    <Button className="pok"
      variant="secondary"
      onClick={handleCloseBR14}
      style={{
        padding: '12px 25px',
        fontSize: '18px',
        borderRadius: '10px',
        backgroundColor: '#6c757d',
        border: 'none',
      }}
    >
      بستن
    </Button>

  </Modal.Footer>
</Modal>



      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
