import React, { useState, useContext } from "react";
import "../Login/login.css";
import "../Login/global.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "jalali-moment";

export const Login = () => {
  const [name, Setname] = useState("");
  const [chk, SetChk] = useState("");
  const nav = useNavigate();
  const [userId,setuserId] = useState("")
  const datesr =  moment(new Date(), "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD")

  const insertAndCheck = async () => {
    try {
      
      await axios
        .post("https://drp.academyxray.com/Register", {
          name: name,
          datesr:datesr
        })
        .then((getResponse) => {
          if(name.length > 0){
          SetChk("0");
          setuserId(name)
          localStorage.setItem('userId', userId);
          nav("/Main", { state: { name,userId } });
       
          }else{}
        })
        .catch((res) => {
          if(name.length > 0){
          SetChk("1");
          setuserId(name)
          localStorage.setItem('userId', userId);
          nav("/Main", { state: { name,userId } });
          }else{}
        });
    } catch (ex) {}
  };

  return (
    <>
      <div className="login-warninglo" dir="ltr">
        <div className="swatch22lo" />
        <input
          className="username1lo"
          placeholder="Enter your name"
          type="text"
          onChange={(e) => Setname(e.target.value) + setuserId(e.target.value)}
        />
        <br />

        {/*         {chk === "1" ? (
          <div className="inline-messagelo">
            <img
              alt=""
              className="inline-message-childlo"
              src="/imgq/group-5542.svg"
            />
            <div className="div9lo">Your name is duplicated</div>
          </div>
        ) : (
          <div className="inline-messagelo">
            <img
              alt=""
              className="inline-message-childlo"
              src="/imgf/group-5542.svg"
            />
            <div className="div9lo" hidden>
              Your name is duplicated
            </div>
          </div>
        )} */}

        <div className="sign-in-btn4lo">
          <a className="button-text4lo" onClick={insertAndCheck}>
            <div className="label4lo">Next</div>
            <img
              alt=""
              className="person-icon6lo"
              src={"/img/arrowforward.svg"}
            />
          </a>
        </div>
      </div>
    </>
  );
};
