import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import plus from "../asset/img/plus.png";
import {Helmet} from "react-helmet";

export const ManageTest = () => {
  const [tokenID, settokenID] = useState("");
  const [name, setName] = useState("");
  const [less, setless] = useState([]);
  const [one, setOne] = useState("");
  const [two, setTwo] = useState("");
  const [tree, setTree] = useState("");
  const [num, setNum] = useState(10);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [remainingQuestions, setRemainingQuestions] = useState(0);

  useEffect(() => {
    settokenID(Math.floor(Math.random() * 58210208) + 15 * 2 * 8);
  }, []);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/listAllR");
      setless(await res.clone().json());
    };
    getAllFiled();
  }, [less]);

  useEffect(() => {
    const options = Array.from({ length: num }, (_, index) => index + 1);
    setQuestionOptions(options);
  }, [num]);

  const handleDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/deleteOneR/${id}`)
      .then((response) => {
        setless((values) => values.filter((item) => item.id !== id));
        toast.success("حذف با موفقیت انجام شد");
      });
  };

  const updateRecord = (id) => {
    toast.success("ویرایش انجام شد");
  };

/*    const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    if (shouldUpdate) {
      // جمع اولویت‌ها
      const totalPriority = less.reduce((sum, item) => sum + Number(item.one), 0);
      // محاسبه سوالات باقی‌مانده
      const remaining = num - totalPriority;
      setRemainingQuestions(remaining);
  
      // اضافه کردن ردیف جدید با وضعیت دروس "تصادفی" و درجه سختی "آسان"
      setless((prevLess) => [
        ...prevLess,
        {
          id: Date.now(), // استفاده از زمان به عنوان id یکتا
          one: remaining,
          two: "تصادفی",
          tree: "آسان",
        },
      ]);
      
      setShouldUpdate(false); // جلوگیری از به‌روزرسانی دوباره
    }
  }, [shouldUpdate, num, less]); // نظارت بر تغییرات و جلوگیری از لوپ بی‌نهایت
  
 */
  const handleConfirm = () => {
   // setShouldUpdate(true); // فعال کردن به‌روزرسانی در صورتی که باید محاسبه شود
  };
  const handelRegister = async() => {
    // ارسال داده‌ها به سرور
   await axios.post("https://drp.academyxray.com/RegisterREG", {
      token: tokenID,
      one: one,
      two: two,
      tree: tree,
      num: num,
    })
    .then(() => {
     toast.success("با موفقیت ثبت شد");
    })
    .catch((error) => {
      toast.error("ثبت با خطا مواجه شد");
      console.error("Error during registration:", error);
    });
  };
  
  

  return (
    <>
      <Helmet>
        <title>تنظیمات آزمون</title>
      </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku">
              <h4 className="pok">تنظیمات آزمون</h4>
              <hr />
              <div className="container-fluid">
                <div className="row">
                  <p className="mr-1 mt-1 pok">تعداد کل سوالات آزمون:</p>
                  <p className="mr-2" style={{ color: "blue" }}>
                    <input
                      style={{ borderRadius: "20px" }}
                      type="text"
                      className="form-control pok"
                      value={num}
                      onChange={(e) => setNum(e.target.value)}
                    />
                  </p>
                  <td>
                    <button
                      style={{ borderRadius: "20px" }}
                      className="btn btn-outline-warning mr-2 pok"
                      onClick={handleConfirm}
                    >
                      تایید
                    </button>
                  </td>
                </div>

                <hr />
                <div className="row mb-2">
                  <div className="col-xs-12 col-sm-3 mt-3">
                    <select
                      style={{ borderRadius: "20px" }}
                      onChange={(e) => setOne(e.target.value)}
                      className="form-control pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"0"}>
                        تعداد سوال
                      </option>
                      {questionOptions.map((number) => (
                        <option className="pok" value={number} key={number}>
                          {number}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xs-12 col-sm-3 mt-3">
                    <select
                      style={{ borderRadius: "20px" }}
                      onChange={(e) => setTwo(e.target.value)}
                      className="form-control pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"0"}>
                        وضعیت دروس
                      </option>
                      <option className="pok" value={"خوانده شده"}>
                        خوانده شده
                      </option>
                      <option className="pok" value={"خوانده نشده"}>
                        خوانده نشده
                      </option>
                      <option className="pok" value={"تصادفی"}>
                        تصادفی
                      </option>
                    </select>
                  </div>
                  <div className="col-xs-12 col-sm-3 mt-3">
                    <select
                      style={{ borderRadius: "20px" }}
                      onChange={(e) => setTree(e.target.value)}
                      className="form-control pok"
                      name="unitRF"
                    >
                      <option className="pok" value={"0"}>
                        درجه سختی
                      </option>
                      <option className="pok" value={"آسان"}>
                        آسان
                      </option>
                      <option className="pok" value={"متوسط"}>
                        متوسط
                      </option>
                      <option className="pok" value={"سخت"}>
                        سخت
                      </option>
                    </select>
                  </div>
                  <div className="col-xs-12 col-sm-1 mt-3">
                    <img
                      src={plus}
                      height="30px"
                      width="30px"
                      className="mr-2"
                      style={{ cursor: "pointer" }}
                      onClick={handelRegister}
                    />
                  </div>
                </div>
              </div>
              <p></p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="pok">اولویت</th>
                      <th className="pok">وضعیت دروس</th>
                      <th className="pok">درجه سختی</th>
                      <th className="pok">ویرایش/حذف</th>
                      <th></th>
                    </tr>
                  </thead>
                  {less.map((item, index) => {
                    const { id } = item;
                    return (
                      <tbody className="table-group-divider table-divider-color" key={item.id}>
                        <tr>
                          <td className="pok">{item.one}</td>
                          <td className="pok">{item.two}</td>
                          <td className="pok">{item.tree}</td>

                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => updateRecord(id)}
                            />
                            <img
                              src={del}
                              height="30px"
                              width="30px"
                              className="mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
{/*                   <tbody>
                    <tr>
                      <td className="pok">{remainingQuestions}</td>
                      <td className="pok">تصادفی</td>
                      <td className="pok">آسان</td>
                      <td></td>
                    </tr>
                  </tbody> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

