import "./App.css";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";

// App Pwa
import { Login } from "./App/Login/Login";
import { Main } from "./App/Main/Main";
import { MainTow } from "./App/MainTwo/MainTow";
import { List } from "./App/List/List";
import { More } from "./App/More/More";
import { Test } from "./App/Test/Test";
import { Toggle } from "./App/Toggle/Toggle";
import { Overview } from "./App/OverView/Overview";
import { Analyze } from "./App/OverView/Analyze";
import { Exam } from "./App/OverView/Exam";
import { About } from "./App/OverView/About";
import { ResultTest } from "./App/Result/ResultTest";
import { Loading } from './App/Loading'
import { ExitProgram } from './App/ExitProgram'

// End App Pwa

// WebSite
import { AdminPage } from "./Dashboard/AdminPage";
import { LoginAdmin } from "./Dashboard/LoginAdmin";
import { PanelAdmin } from "./Dashboard/PanelAdmin";
import { AdminList } from "./Dashboard/AdminList";

import { Mainx } from "./Main/Mainx";
import { MainTowx } from "./Main/MainTowx";
import { ListItem } from "./Main/ListItem";
import { ToggleImg } from "./Main/ToggleImg";
import { Question } from "./Main/Question";
import { Users } from "./Main/Users";
import { Report } from "./Main/Report";
import { ReportQues } from './Report/ReportQues'
import { ReportUser } from './Report/ReportUser'
import { ManageTest } from "./Main/ManageTest";
import { ReportChart } from "./Report/ReportChart";
import { QuestionAnswer } from "./Report/QuestionAnswer";
import { ReportUserAll } from "./Report/ReportUserAll";
import { ReportAllTest } from "./Report/ReportAllTest";
import { ReportAllLess } from "./Report/ReportAllLess";
import { ReportAzmoon } from "./Report/ReportAzmoon";

// End WebSite

const App = () => {
  const LoginUsers = () => {
    return (
      <div>
        <Login />
        <Outlet />
      </div>
    );
  };

  const MainApp = () => {
    return (
      <div>
        <Main />
        <Outlet />
      </div>
    );
  };

  const Layout = () => {
    return (
      <div>
        <AdminPage />
        <Outlet />
      </div>
    );
  };

  const AdminPageLogin = () => {
    return (
      <div>
        <LoginAdmin />
      </div>
    );
  };

  const ReportAllQues = () => {
    return (
      <div>
        <Report />
        <Outlet />
      </div>
    );
  };

  const LoadingUserView = () => {
    return (
      <div>
        <Loading />
      </div>
    );
  };

  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoadingUserView />,
    },

    {
      path: "/ExitDeveloper",
      element: <ExitProgram />,
    },
    
     {
      path: "/Login",
      element: <LoginUsers />,
    },
    
    {
      path: "/Main",
      element: <MainApp />,
    },
    {
      path: "/MainTo",
      element: <MainTow />,
    },
    {
      path: "/List",
      element: <List />,
    },
    {
      path: "/More",
      element: <More />,
    },
    {
      path: "/Test",
      element: <Test />,
    },
    {
      path: "/Toggle",
      element: <Toggle />,
    },

    {
      path: "/Overview",
      element: <Overview />,
    },
    {
      path: "/Analyze",
      element: <Analyze />,
    },
    {
      path: "/Exam",
      element: <Exam />,
    },
    {
      path: "/About",
      element: <About />,
    },
    {
      path: "/ResultTest",
      element: <ResultTest />,
    },

    // WebSite Component

    {
      path: "/loginAdmins",
      element: <AdminPageLogin />,
    },

    {
      path: "/panelAdmin",
      element: <Layout />,
      children: [
        {
          path: "/panelAdmin/PanelAdmins",
          element: <PanelAdmin />,
        },
        {
          path: "/panelAdmin/Mainx",
          element: <Mainx />,
        },
        {
          path: "/panelAdmin/MainTowx",
          element: <MainTowx />,
        },
        {
          path: "/panelAdmin/ListItem",
          element: <ListItem />,
        },
        {
          path: "/panelAdmin/ToggleImg",
          element: <ToggleImg />,
        },
        {
          path: "/panelAdmin/Question",
          element: <Question />,
        },
        {
          path: "/panelAdmin/UsersList",
          element: <Users />,
        },
        {
          path: "/panelAdmin/ListAdmin",
          element: <AdminList />,
        },
        {
          path: "/panelAdmin/ReportAll/ReportUsersAll",
          element: <ReportUserAll />,
        },
        {
          path: "/panelAdmin/ReportAll/ReportAlltest",
          element: <ReportAllTest />,
        },
        {
          path: "/panelAdmin/ReportAll/ReportDarce",
          element: <ReportAllLess />,
        },
        {
          path: "/panelAdmin/ReportAll/Azm",
          element: <ReportAzmoon />,
        },
        
        {
          path: "/panelAdmin/ReportAll",
          element: <ReportAllQues />,
           children: [
              {
                path: "/panelAdmin/ReportAll/R-Ques",
                element: <ReportQues />,
              },
              {
                path: "/panelAdmin/ReportAll/R-User",
                element: <ReportUser />,
              },
              {
                path: "/panelAdmin/ReportAll/AnswerQ",
                element: <QuestionAnswer />,
              },                         
          ]
        },
        {
          path: "/panelAdmin/ManagePageTest",
          element: <ManageTest />,
        },
        {
          path: "/panelAdmin/ChartReport",
          element: <ReportChart />,
        },
        
      ],
    },
  ]);
  return (
    <div className="app">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
