import React, { useEffect, useState } from "react";
import "../MainTwo/MainTwo.css";
import "../MainTwo/global.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import "../MainTwo/pop.css";

export const MainTow = () => {
  const location = useLocation();
  const nav = useNavigate();

  const name = location.state.name;
  const onv = location.state.onv;
  const gru = location.state.gru;
  const userId = location.state.userId;

  const [mainList, setMainList] = useState([]);

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainTowR/${onv}`
      );
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [onv]);

  const [imgSelect, setimgSelect] = useState([]);

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/chkImgae");
      setimgSelect(await res.json());
    };
    getAllProductsRT();
  }, []);

  const gotopage = (name, onv, gru) => {
    nav("/List", { state: { name, onv, gru,userId } });
  };

  const gotopage2 = (name, onv) => {
    nav("/Test", { state: { name, onv,userId } });
  };

  const gotopageU = (name, onv, gru) => {
    nav("/Overview", { state: { name, onv, gru,userId } });
  };

  const gotopageB = (name, onv, gru) => {
    nav("/main", { state: { name, onv, gru,userId } });
  };

  const [showBR14, setShowBR14] = useState(false);
  const handleCloseBR14 = () => setShowBR14(false);
  const handleShowBR14 = () => setShowBR14(true);

  const showHandelpopsocial = () => {
    handleShowBR14(true);
  };

  const hidePop = () => {
    handleCloseBR14(true);
  };

  return (
    <>
    
      <div className="main2as" dir="ltr">
      <div class="profile4kl">Dental Panoramic
          <p style={{textAlign:'center'}}>R A D I O L O G Y</p>
      </div>
        <div className="swatch56as po1"><span style={{color:'white'}}>{onv}</span></div>
        <div className="swatch58as" />

        {imgSelect.map((item) => {
          return <img alt="" className="image-8-iconas" src={item.img1} />;
        })}

        <div className="row-innn">
          {mainList.map((item, index) => {
            const { id, title, descs, main_id } = item;
            return (
              <div
                className="row-inset10as"
                style={{ cursor: "pointer" }}
                onClick={() => gotopage(name, onv, title)}
              >
                <div className="div31as" style={{ fontSize: "16px" }}>
                  {index + 1}. 
                </div>
                <div className="title10as" style={{ fontSize: "16px" }}>
                  {item.title}
                </div>
                <div className="trailing-accessories10as">
                  <div className="disclosure4as" />
                </div>
                <div className="grabber20as">
                  <div className="grabber19as">􀌇</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row-inset12as" style={{ cursor: "pointer" }}>
          <div className="edit-button22as">
            <div className="edit-button19as">
              <div className="div30as">􀆅</div>
            </div>
          </div>
          <div className="image9as">
            <img
              alt=""
              className="app-icons9as"
              src={"/img/app-icons@2x.png"}
            />
          </div>
          <div className="div35as"></div>
          <div
            className="title12as"
            style={{ fontSize: "16px" }}
            onClick={showHandelpopsocial}
          >
            Exam
          </div>
          <div className="trailing-accessories12as">
            <div className="checkmark6as" />
          </div>
          <div className="grabber24as">
            <div className="grabber19as">􀌇</div>
          </div>
        </div>
      </div>

      <div class="profile4X2" hidden>
            {onv}
      </div>
      <a class="swatch-parent601" onClick={() => gotopageU(onv, name)}>
        <img alt="" src="/img/Group 5551.png" />
      </a>
      <a class="swatch-parent622" onClick={() => gotopageB(onv, name)}>
        <img alt="" src="/img/Group 5550.png" />
      </a>
      <div class="swatch-parent63">
        <div class="swatch101"></div>
        <img class="person-icon22" alt="" src="/img/person.svg" />
      </div>
      <Modal show={showBR14} onHide={handleCloseBR14}>
        <div class="pop-up-exitK" dir="ltr">
          <div class="swatch-parent19K">
            <div class="swatch48K"></div>
            <div class="do-you-wantK" style={{ color: "white" }}>
              Do you want to start the exam ?
            </div>
            <div class="sign-in-btn30K">
              <div class="button-text30K mr-1" onClick={hidePop}>
                <div class="falseK" style={{ color: "white" }}>
                  Cancel
                </div>
              </div>
              <div
                class="button-text31K mr-1"
                onClick={() => gotopage2(name, onv)}
              >
                <div class="falseK" style={{ color: "white" }}>
                  Ok
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
