import React from 'react'
import ReactSpinnerTimer from "react-spinner-timer";
import { Link, useNavigate } from "react-router-dom";
import './load.css'
import { BarLoader,ClipLoader,BeatLoader,CircleLoader,MoonLoader,HashLoader   } from 'react-spinners';

export const Loading = () => {

const userId = localStorage.getItem('userId')


const nav = useNavigate();

 const handleChange = (lap) => {
      if (lap.isFinish)
        console.log('No')
    else
    if(userId === null){
      // nav("/Login");
      console.log('No')
    }else{
     // nav("/Main", { state: { userId } });
     console.log('No')
    }
    console.log('No')
  };

  setTimeout(() => {
    if(userId === null){
       nav("/Login");
      console.log('No')
    }else{
      nav("/Main", { state: { userId } });
     console.log('NAAXSSo')
    }
  }, 5000);

  return (
        <>

    <img className='imgload' src='/img/load.png' />

  <div className='ll' style={{zIndex:"99", textAlign:'center'}}>
  <BeatLoader  color="purple" height={5} width={100} loading={true} />

{/*     <ReactSpinnerTimer
                timeInSeconds={5}
                totalLaps={5}
                isRefresh={false}
                onLapInteraction={handleChange}
                isPause={false}
            /> */}
       </div>
 
   </>
  )
}
