import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import exl from '../asset/img/excel.png'
export const ExcelQuesTwo = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  //id,nameF,telF,meliF,statF,nameS,mghS,date,descs,sheF,sta,image1,avge
  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "ردیف",
          "سوال",
          "درجه سختی",
          "پاسخ یک",
          "پاسخ دو",
          "پاسخ سه",
          "پاسخ چهار",
          "پاسخ صحیح",
        ],
      ],
      { origin: "A1" }
    );
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={exl}
        style={{ cursor: "pointer" }}
        height={"50px"}
        width={"50px"}
        onClick={(e) => exportToCSV(apiData, fileName)}
        title="دریافت گزارش اکسل"
      />
    </div>
  );
};
