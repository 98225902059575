import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import {Helmet} from "react-helmet";

export const Mainx = () => {

  const [title, setTitle] = useState("");
  const [file1, setfile1] = useState("");

  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };


  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]);

  const listDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDelete/${id}`)
      .then((response) => {
        setMainList((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const updateImage = async (id) => {
    try {
      if (!file1) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image", file1);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/updateRS/${id}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage2 = async () => {
    try {
      if (!file1) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image", file1);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

 const editImage = async() => {
  updateImage2();
 }

 const [rsp,setRsp] = useState([])
 useEffect(() => {
  const getAllProductsRT = async () => {
    const res = await fetch("https://drp.academyxray.com/panelAdmin/selectALL");
    setRsp(await res.json());
  };
  getAllProductsRT();
}, [mainList]);

 const [isAccordionOpen, setIsAccordionOpen] = useState(false); // کنترل باز و بسته بودن آکاردئون

 const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

  return (
    <div>
      <Helmet>
        <title>صفحه اصلی برنامه</title>
      </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku">
              <div className="container-fluid">
                {/* بخش آکاردئون */}
                <div className="accordion-container">
                  <div className="accordion-header d-flex justify-content-between align-items-center">
                    <h5 className="pok">ایجاد کردن</h5>
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={toggleAccordion}
                      style={{ borderRadius: "50%", width: "30px", height: "30px" }}
                    >
                      {isAccordionOpen ? "-" : "+"}
                    </button>
                  </div>

                  {/* محتوای آکاردئون */}
                  {isAccordionOpen && (
                    <div className="accordion-body mt-3">
  <h4 className="pok">صفحه اصلی برنامه</h4>
  <hr />
  <div className="row mb-2">
    <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
      <div className="d-flex flex-column align-items-center mx-1 px-2">
        <p className="pok">تغییر عکس بک‌ گراند</p>
        <input
          type="file"
          style={{ borderRadius: "20px", width: "150px" }}
          name="file1"
          className="form-control"
          onChange={handleFile1}
        />
      </div>
      <div className="d-flex flex-column align-items-center mx-1 px-2">
        <label
          className="pok"
          style={{ color: "green", textAlign: "center" }}
        >
          اندازه پیشنهادی
          <span style={{ color: "red", display: "block" }}>290*550</span>
        </label>
      </div>
      <div className="d-flex flex-column align-items-center mx-1 px-2">
      {rsp.length > 0 && 
  rsp.slice(-1).map((item) => (
    <img
      src={item.img}
      width="60px"
      height="60px"
      style={{ borderRadius: "50%" }}
      alt="Preview"
      key={item.id} // مطمئن شوید که هر آیتم یک `id` یا کلید یکتا دارد
    />
  ))
}
      </div>
      <div className="d-flex flex-column align-items-center mx-1 px-2">
        <button
          style={{
            borderRadius: "20px",
            width: "100px",
          }}
          className="btn btn-outline-success pok"
          onClick={editImage}
        >
          ویرایش عکس
        </button>
      </div>
    </div>
  </div>
</div>

   )}
                </div>
              </div>

              <br />

              {/* جدول */}
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr className="pok">
                      <th className="pok">ردیف</th>
                      <th className="pok">عنوان</th>
                      <th className="pok">تصویر</th>
                      <th className="pok">سایز</th>
                      <th className="pok">ویرایش</th>
                    </tr>
                  </thead>
                  {mainList.map((item, index) => (
                    <tbody
                      className="table-group-divider table-divider-color"
                      key={item.id}
                    >
                      <tr>
                        <td className="pok">{index + 1}</td>
                        <td className="pok">{item.title}</td>
       
                        <td>
                          <img
                            src={item.img}
                            width={"80px"}
                            height={"50px"}
                            alt="Item"
                          />
                        </td>
                        <td className="pok">{item.siz}
                            <input
                                type="file"
                                style={{ borderRadius: "20px", width: "150px" }}
                                name="file1"
                                className="form-control"
                                onChange={handleFile1}
                              />
                        </td>
                      <td>
                          <img
                            src={edit}
                            height="30px"
                            width="30px"
                            style={{ cursor: "pointer" }}
                            onClick={() => updateImage(item.id)}
                            alt="Edit"
                          /> 
                          </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
