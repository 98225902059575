import React from 'react'

export const ReportAllLess = () => {
  return (
   <>
       <body
          className="hold-transition sidebar-mini"
          style={{ backgroundImage: "none" }}
        >
          <div className="wrapper">
            <div className="content-wrapper pku4">
              <div className="content-header">
                <div className="container-fluid"></div>
           <div class="table-responsive">
                    <table class="table bg-white custom-rounded t-24 table-responsive">
                        <thead>
                            <tr class="text-center">
                            <th className='pok'>کد کاربر                 </th>
                            <th className='pok'>نام کاربر                </th>
                            <th className='pok'>کد درس                    </th>
                            <th className='pok'>گروه اصلی درس                    </th>
                            <th className='pok'>زیر گروه اول          </th>
                            <th className='pok'>نام درس  </th>
                            <th className='pok'>زمان مطالعه   </th>
                            <th className='pok'>مرور/دفعه اول</th>
                            <th className='pok'>تاریخ</th>
                            </tr>
                        </thead>
                    </table>
            </div>
    </div>
       </div>
       </div>
   </body>
   </>
  )
}
