import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import eye from "../asset/img/eye.png";
import eye1 from "../asset/img/eye1.png";
import Modal from "react-bootstrap/Modal";
import { ExcelAnswer } from './ExcelAnswer'



export const QuestionAnswer = () => {

  const fileName = "گزارش  سوالات";
  const filename1 = "گزارش سوالات";

 const [mainList, setMainList] = useState([]);
    useEffect(() => {
      const getAllProductsRT = async () => {
        const res = await fetch("https://drp.academyxray.com/panelAdmin/listMainReperQ");
        setMainList(await res.json());
      };
      getAllProductsRT();
    }, [mainList]);

 return (
    <>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          
          <div className="content-wrapper pku">
            <div className="content-header">
              <div className="container">
                <div className="row mb-2">
                <div className="col-12 col-sm-6 col-md-3">
  <Link to={"/panelAdmin/ReportAll/R-Ques"}>
    <div className="info-box">
      <span className="info-box-icon bg-info elevation-1">
      <i className="fa fa-leanpub" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش آزمون
        </span>
      </div>
    </div>
    </Link>
  </div>
  
  <div className="col-12 col-sm-6 col-md-3">
    <Link to={"/panelAdmin/ReportAll/AnswerQ"}>
    <div className="info-box mb-3">
      <span className="info-box-icon bg-danger elevation-1">
      <i className="fa fa-leanpub" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش سوالات
        </span>
      </div>
    </div>
    </Link>
  </div>
  <div className="clearfix hidden-md-up" />
  <div className="col-12 col-sm-6 col-md-3">
    <div className="info-box mb-3">
      <span className="info-box-icon bg-success elevation-1">
        <i className="fa fa-leanpub" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش آزمونهای انجام شده
        </span>
      </div>
    </div>
  </div>
 
    <div className="col-12 col-sm-6 col-md-3">
    <Link to={"/panelAdmin/ReportAll/R-User"}>
    <div className="info-box mb-3">
      <span className="info-box-icon bg-warning elevation-1">
        <i className="fa fa-users" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text pok">
          گزارش کاربران
        </span>
      </div>
    </div>
    </Link>
  </div>
                  </div>
                    </div>
                    </div>
                    <hr />
                   <div className="App">
                   <ExcelAnswer apiData={mainList} fileName={fileName} />
                   </div>
                    <hr />
    <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">دسته اصلی</th>
                      <th className="pok" style={{textAlign:'center'}}>زیر گروه اول</th>
                      <th className="pok" style={{textAlign:'center'}}>زیر گروه دوم</th>
                      <th className="pok" style={{textAlign:'center'}}>زیر گروه سوم</th>
                      <th className="pok" style={{textAlign:'center'}}>وضعیت سوال</th>
                      <th className="pok" style={{textAlign:'center'}}>تاریخ</th>
                    </tr>
                  </thead>
                  {mainList.map((item, index) => {
                    const { id, title, img } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.lione}</td>
                          <td>{item.litwo}</td>
                          <td>{item.litree}</td>
                          <td>{item.lifour}</td>
                          <td>{item.token}</td>
                          <td>{item.date}</td>

                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
    </div>
    
    </div>
    </body>
    </>
  )
}
