import React from 'react'
import '../Main/ipc.css'
export const PanelAdmin = () => {
  return (
    <>
<body className="hold-transition sidebar-mini pku">
  <div className="wrapper">
      <div className="content-wrapper">
      <div className="content-header ">
        <div className="container-fluid">
<div className="row">
  <div className="col-12 col-sm-6 col-md-3">
    <div className="info-box">
      <span className="info-box-icon bg-info elevation-1">
        <i className="fa fa-gear" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text">
          ترافیک Cpu
        </span>
        <span className="info-box-number">
          10
          <small>
            %
          </small>
        </span>
      </div>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-3">
    <div className="info-box mb-3">
      <span className="info-box-icon bg-danger elevation-1">
        <i className="fa fa-google-plus" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text">
          لایک‌ها
        </span>
        <span className="info-box-number">
          41,410
        </span>
      </div>
    </div>
  </div>
  <div className="clearfix hidden-md-up" />
  <div className="col-12 col-sm-6 col-md-3">
    <div className="info-box mb-3">
      <span className="info-box-icon bg-success elevation-1">
        <i className="fa fa-shopping-cart" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text">
          فروش
        </span>
        <span className="info-box-number">
          760
        </span>
      </div>
    </div>
  </div>
  <div className="col-12 col-sm-6 col-md-3">
    <div className="info-box mb-3">
      <span className="info-box-icon bg-warning elevation-1">
        <i className="fa fa-users" />
      </span>
      <div className="info-box-content">
        <span className="info-box-text">
          اعضای جدید
        </span>
        <span className="info-box-number">
          2,000
        </span>
      </div>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-md-12">
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">
          گزارش ماهیانه
        </h5>
        <div className="card-tools">
          <button
            className="btn btn-tool"
            data-widget="collapse"
            type="button"
          >
            <i className="fa fa-minus" />
          </button>
          <div className="btn-group">
            <button
              className="btn btn-tool dropdown-toggle"
              data-toggle="dropdown"
              type="button"
            >
              <i className="fa fa-wrench" />
            </button>
            <div
              className="dropdown-menu dropdown-menu-left"
              role="menu"
            >
              <a
                className="dropdown-item"
                href="#"
              >
                منو اول
              </a>
              <a
                className="dropdown-item"
                href="#"
              >
                منو دوم
              </a>
              <a
                className="dropdown-item"
                href="#"
              >
                منو سوم
              </a>
              <a className="dropdown-divider" />
              <a
                className="dropdown-item"
                href="#"
              >
                لینک
              </a>
            </div>
          </div>
          <button
            className="btn btn-tool"
            data-widget="remove"
            type="button"
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-8">
            <p className="text-center">
              <strong>
                نمودار فروش
              </strong>
            </p>
            <div className="chart">
              <canvas
                height="180"
                id="salesChart"
                style={{
                  height: '180px'
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <p className="text-center">
              <strong>
                میزان پیشرفت اهداف
              </strong>
            </p>
            <div className="progress-group">
              محصولات اضافه شده به سبد خرید
              <span className="float-left">
                <b>
                  160
                </b>
                /200
              </span>
              <div className="progress progress-sm">
                <div
                  className="progress-bar bg-primary"
                  style={{
                    width: '80%'
                  }}
                />
              </div>
            </div>
            <div className="progress-group">
              خرید انجام شده
              <span className="float-left">
                <b>
                  310
                </b>
                /400
              </span>
              <div className="progress progress-sm">
                <div
                  className="progress-bar bg-danger"
                  style={{
                    width: '75%'
                  }}
                />
              </div>
            </div>
            <div className="progress-group">
              <span className="progress-text">
                بازدید صفحات ویژه
              </span>
              <span className="float-left">
                <b>
                  480
                </b>
                /800
              </span>
              <div className="progress progress-sm">
                <div
                  className="progress-bar bg-success"
                  style={{
                    width: '60%'
                  }}
                />
              </div>
            </div>
            <div className="progress-group">
              سوالات ارسالی
              <span className="float-left">
                <b>
                  250
                </b>
                /500
              </span>
              <div className="progress progress-sm">
                <div
                  className="progress-bar bg-warning"
                  style={{
                    width: '50%'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-sm-3 col-6">
            <div className="description-block border-right">
              <span className="description-percentage text-success">
                <i className="fa fa-caret-up" />
                {' '}17%
              </span>
              <h5 className="description-header">
                تومان 35,210.43
              </h5>
              <span className="description-text">
                کل گردش حساب
              </span>
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="description-block border-right">
              <span className="description-percentage text-warning">
                <i className="fa fa-caret-left" />
                {' '}0%
              </span>
              <h5 className="description-header">
                تومان 10,390.90
              </h5>
              <span className="description-text">
                فروش کل
              </span>
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="description-block border-right">
              <span className="description-percentage text-success">
                <i className="fa fa-caret-up" />
                {' '}20%
              </span>
              <h5 className="description-header">
                تومان 24,813.53
              </h5>
              <span className="description-text">
                سود کل
              </span>
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="description-block">
              <span className="description-percentage text-danger">
                <i className="fa fa-caret-down" />
                {' '}18%
              </span>
              <h5 className="description-header">
                1200
              </h5>
              <span className="description-text">
                اهداف
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
   </div>
  </div>
 </div>
</body>
    </>
  )
}
