import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import '../Dashboard/fontR.css'
import '../Main/ipc.css'
import {Helmet} from "react-helmet";
import axios from 'axios'

export const LoginAdmin = () => {
  const [meli, setMeli] = useState("");
  const [pass, setPass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post("https://drp.academyxray.com/login", {
        meli,
        pass,
      });
  
      if (response.data.success) {
        // در صورت موفقیت، کاربر را به صفحه مورد نظر هدایت کنید
        navigate("/panelAdmin/ReportAll/R-Ques");
      } else {
        setErrorMessage(response.data.message); // پیام خطا از سرور
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message); // پیام خطا از سرور
      } else {
        setErrorMessage("خطای ارتباط با سرور. لطفاً دوباره تلاش کنید."); // پیام پیش‌فرض
      }
    }
  };
  

  return (
    <>
      <Helmet>
        <title>پنل مدیریت</title>
      </Helmet>
      <div
        className="login-page"
        style={{
          backgroundColor: "#f0f2f5",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="login-box"
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            padding: "30px",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <div className="login-logo pok" style={{ textAlign: "center", marginBottom: "20px" }}>
            <h2 style={{ color: "#333", fontWeight: "700" }}>پنل مدیریت</h2>
          </div>
          <div className="card-body">
            <p
              className="login-box-msg pok"
              style={{
                textAlign: "center",
                color: "#555",
                fontSize: "14px",
                marginBottom: "20px",
              }}
            >
              لطفاً کد ملی و رمز عبور خود را وارد کنید
            </p>
            {errorMessage && (
              <div
                style={{
                  color: "red",
                  textAlign: "center",
                  marginBottom: "15px",
                }}
                className="pok"
              >
                {errorMessage}
              </div>
            )}
            <form onSubmit={handleLogin}>
            <label className="pok"
                    htmlFor="meli"
                    style={{
                      display: "block",
                      fontSize: "14px",
                      color: "#555",
                      marginBottom: "5px",
                    }}
                  >
                    کد ملی
                  </label>
            <div className="input-group pok" style={{ marginBottom: "15px" }}>

                  <input
                    type="text"
                    id="meli"
                    className="form-control"
                    placeholder="کد ملی"
                    maxLength={10}
                    value={meli}
                    onChange={(e) => setMeli(e.target.value)}
                    required
                    style={{
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  />
                </div>
                <label
                className="pok"
              htmlFor="password"
              style={{
                display: "block",
                fontSize: "14px",
                color: "#555",
                marginBottom: "5px",
              }}
            >
              رمز عبور
            </label>
                <div className="input-group pok" style={{ marginBottom: "15px" }}>

            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="رمز عبور"
              maxLength={12}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              required
              style={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
              }}
            />
          </div>
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div className="col-8 pok">
                  <label style={{ fontSize: "14px", color: "#555" }} className="pok">
                    <input type="checkbox" style={{ marginRight: "5px" }} />
                    مرا به خاطر بسپار
                  </label>
                </div>
                <div className="col-4">
                  <button
                    type="submit"
                    className="btn btn-primary pok"
                    style={{
                      backgroundColor: "#007bff",
                      border: "none",
                      borderRadius: "8px",
                      padding: "10px 20px",
                      width: "100%",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  >
                    ورود
                  </button>
                </div>
              </div>
            </form>
            <div style={{ textAlign: "center", marginTop: "15px" }}>
              <a
                className="pok"
                href="#"
                style={{
                  color: "#007bff",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                رمز عبور خود را فراموش کرده‌اید؟
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};