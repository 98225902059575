import "../Test/global.css";
import "../Test/test.css";
import { Header } from "../HeaderEX/Header";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "jalali-moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
/* import { useTime } from 'react-timer-hook'; */
/* import CountUp, { useCountUp } from 'react-countup'; */

export const Test = () => {

  const location = useLocation();
  const nav = useNavigate();

  const onv = location.state.onv;
  const name = location.state.name;
 /*  const tokenID = location.state.token; */

  const [dataAll, setDataAll] = useState([]);
  const [filterAll, setFilterAll] = useState(dataAll);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 1;
  const lastIndesx = currentPage * recordsPerPage;
  const firstIndex = lastIndesx - recordsPerPage;
  const records = filterAll.slice(firstIndex, lastIndesx);
  const npage = Math.ceil(filterAll.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const [str, setStr] = useState("");
  const [count, setCount] = useState(1);
  let newString;
  let arrow;
  const [userid, setUserid] = useState("");
  const [grid, setGrid] = useState("");
  const [ans_true, setAnseT] = useState("");
  const [ans_false, setAnseF] = useState("");
  const [ques, setQues] = useState("");
  const [token, setToken] = useState("");
  const [date, setDate] = useState("");
  const [question, setQuestion] = useState("");
  const [alc, setAlc] = useState("");


  useEffect(() => {
    setToken(Math.floor(Math.random() * 85365212) + 15);
  }, []);

  useEffect(() => {
    const getAllProducts = async () => {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/questionFinde/${onv}`
      );
      setDataAll(await res.clone().json());
      setFilterAll(await res.json());
    };
    getAllProducts();
  }, []);

  /*   function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCpage(id) {
    setCurrentPage(id);
  }  */

  function nextPage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage + 1);
      setCount(count + 1);
    }
  }

  const [colorA, setColorA] = useState("");
  const [colorB, setColorB] = useState("");
  const [colorC, setColorC] = useState("");
  const [colorD, setColorD] = useState("");

  const clearState = () => {
    setColorA("");
    setColorB("");
    setColorC("");
    setColorD("");
  };

  const changeColorA = (alc, question) => {
    clearState();
    setColorA("blue");
    setQuestion(question);
    setAlc(alc);
  };

  const changeColorB = (alc, question) => {
    clearState();
    setColorB("blue");
    setQuestion(question);
    setAlc(alc);
  };

  const changeColorC = (alc, question) => {
    clearState();
    setColorC("blue");
    setQuestion(question);
    setAlc(alc);
  };

  const changeColorD = (alc, question) => {
    clearState();
    setColorD("blue");
    setQuestion(question);
    setAlc(alc);
  };

  const [trueT, setTrueT] = useState(0);
  const [falseF, setFalseF] = useState(0);

const NextQuestion = (acm) => {
    setTimes(0)
    if (count === npage) {
      if (acm === alc) {
        if (acm === "ques1") {
          arrow = "1";
          setColorA("green");
          setTrueT(trueT + 1);
          nextTime();
        } else if (acm === "ques2") {
          arrow = "1";
          setColorB("green");
          setTrueT(trueT + 1);
          nextTime();
        } else if (acm === "ques3") {
          arrow = "1";
          setColorC("green");
          setTrueT(trueT + 1);
          nextTime();
        } else if (acm === "ques4") {
          arrow = "1";
          setColorD("green");
          setTrueT(trueT + 1);
          nextTime();
        }
  } else if (acm !== alc) {
        if (acm !== "ques1") {
          arrow = "0";
          setColorA("red");
          setFalseF(falseF + 1);
          nextTime();
        } else if (acm !== "ques2") {
          arrow = "0";
          setColorB("red");
          setFalseF(falseF + 1);
          nextTime();
        } else if (acm !== "ques3") {
          arrow = "0";
          setColorC("red");
          setFalseF(falseF + 1);
          nextTime();
        } else if (acm !== "ques4") {
          arrow = "0";
          setColorD("red");
          setFalseF(falseF + 1);
          nextTime();
        }
     }
     nav("/ResultTest", {
      state: { token, name, onv, npage, trueT, falseF },
});

  } 

  else {
      if (acm === alc) {
        if (acm === "ques1") {
          arrow = "1";
          setColorA("green");
          setTrueT(trueT + 1);
          nextTime();
        } else if (acm === "ques2") {
          arrow = "1";
          setColorB("green");
          setTrueT(trueT + 1);
          nextTime();
        } else if (acm === "ques3") {
          arrow = "1";
          setColorC("green");
          setTrueT(trueT + 1);
          nextTime();
        } else if (acm === "ques4") {
          arrow = "1";
          setColorD("green");
          setTrueT(trueT + 1);
          nextTime();
        }
      } else if (acm !== alc) {
        if (acm !== "ques1") {
          arrow = "0";
          setColorA("red");
          setFalseF(falseF + 1);
          nextTime();
        } else if (acm !== "ques2") {
          arrow = "0";
          setColorB("red");
          setFalseF(falseF + 1);
          nextTime();
        } else if (acm !== "ques3") {
          arrow = "0";
          setColorC("red");
          setFalseF(falseF + 1);
          nextTime();
        } else if (acm !== "ques4") {
          arrow = "0";
          setColorD("red");
          setFalseF(falseF + 1);
          nextTime();
        }
      }
    }
  };

  const nextTime = () => {
    setTimeout(() => {
      clearState();
      register();
      nextPage();
    }, 1000);
  };

  // userid	grid	ans_true	ans_false	ques	token	date
const [times,setTimes] = useState(1)

/*   const {
    seconds,
    minutes,
    hours,
    ampm,
  } = useTime({ format: '12-hour'});
 */
 
  useEffect(() => {
    const interval = setInterval(() => {
        setTimes(times + 1);
    }, 1000);

    //Clearing the interval
    return () => clearInterval(interval);
}, [times]);


 const register = () => {
    /*   try { */
    axios.post("https://drp.academyxray.com/panelAdmin/answerIn", {
      userid: name,
      grid: onv,
      ans_true: question,
      ans_false: alc,
      ques: newString,
      token: token,
      date: moment(new Date(), "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"),
      type: arrow,
      times: times,
      /* tokenID:tokenID */
    });
  };

  
  return (
    <>
      <div className="about-usV" dir="ltr">
      {/* {times} */}
    <div className="swatch99V" />
        {npage >= count ? (
          records.map((item) => {
            const regex = /(<([^>]+)>)/gi;
            newString = item.ques.replace(regex, "");
            return (
              <div className="swatch100V">
                <br />
                <img alt="" className="image-15-icon1V" src={item.img} />
                <div className="lorem-ipsum-dolor5V">{newString}</div>
              </div>
            );
          })
        ) : (
            null
        )}

        {records.map((item) => {
          const { id, ques1, ques2, ques3, ques4, trueq } = item;
          return (
            <div
              className="segmented-control4V"
              style={{
                color: "white",
              }}
            >
              <p></p>
              <div className="sign-in-btn-parentV">
                <div
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    className="button-text7V"
                    style={{ backgroundColor: colorA }}
                    onClick={(e) => changeColorA("ques1", trueq)}
                  >
                    <div className="answerV" style={{ fontFamily: "BOLD" }}>
                      1 - {item.ques1}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    className="button-text7V"
                    style={{ backgroundColor: colorB }}
                    onClick={(e) => changeColorB("ques2", trueq)}
                  >
                    <div className="answerV" style={{ fontFamily: "BOLD" }}>
                      2 - {item.ques2}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    className="button-text7V"
                    style={{ backgroundColor: colorC }}
                    onClick={(e) => changeColorC("ques3", trueq)}
                  >
                    <div className="answerV" style={{ fontFamily: "BOLD" }}>
                      3 - {item.ques3}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    className="button-text7V"
                    style={{ backgroundColor: colorD }}
                    onClick={(e) => changeColorD("ques4", trueq)}
                  >
                    <div className="answerV" style={{ fontFamily: "BOLD" }}>
                      4 - {item.ques4}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="swatch-parent61V">
          <div className="question-1V">
            Question {count} / {npage}
          </div>
        </div>
        <div className="sign-in-btn11V">
        { count === npage ? (
          <div className="button-text11V">
            <div
              className="answerV"
              style={{ cursor: "pointer" }}
              onClick={(e) => NextQuestion(question)}
            >
              Finish
            </div>
          </div>
        ):(
          <div className="button-text11V">
            <div
              className="answerV"
              style={{ cursor: "pointer" }}
              onClick={(e) => NextQuestion(question)}              
            >
              Next
            </div>
          </div>
        )
        }
        </div>
     </div>
      <Header />
    </>
  );
};
