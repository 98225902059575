import React,{useEffect,useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../OverView/exam1.css";
import "../OverView/global.css";
import { Header } from "../Header/Header";

export const Exam = () => {
  const location = useLocation();
  const nav = useNavigate();

  const onv = location.state.onv;
  const name = location.state.name;
  const userId = location.state.userId;

 
  const [mainList, setMainList] = useState([]);

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/findeAppListExam/${userId}`
      );
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, []);

  return (
    <>
      <div className="exam" dir="ltr">
        <div className="swatch127" />
        <div style={{marginTop:"30px"}} className="segmented-control5">
          <div className="button-15">
            <div className="div125">􀓔</div>
            <Link to="/Overview" state={{ name, onv,userId }}>
              <div className="label35">Overview</div>
            </Link>
          </div>
          <div className="button-25">
            <div className="div126">􀓔</div>
            <Link to="/Analyze" state={{ name, onv,userId }}>
              <div className="label36">Analyze</div>
            </Link>
          </div>
          <div className="button-34X">
            <div className="div105X">􀓔</div>
            <div className="label29X">Exam</div>
          </div>
          <div className="button-4">
            <div className="div128">􀓔</div>
            <Link to="/About" state={{ name, onv,userId }}>
              <div className="label38">About</div>
            </Link>
          </div>
        </div>
        <div className="swatch-parent77">
          <div className="swatch129" />
          <div className="exam-result">Exam Result</div>
        </div>
        <div className="swatch-parent79">
          <div className="swatch131" />
          <img alt="" className="person-icon30" src="./public/person5.svg" />
        </div>
        <div className="section-13">
          <div className="ppp">


          {mainList.map((item) => {
            const { 
              id,	
              onv,	
              name,	
              token,	
              num,	
              type,
              date } = item;
            return (
              <div className="mt-1">
            <div className="row-inset31">
              <div className="edit-button60">
                <div className="edit-button61">
                  <div className="div129">􀆅</div>
                </div>
              </div>
              <div className="image31">
                <img
                  alt=""
                  className="app-icons31"
                  src="./public/app-icons@2x.png"
                />
              </div>
              <div className="title31">
                <span>
                  <p className="test1">{item.token}</p>
                  <p className="cbct-scan1">{item.onv}</p>
                </span>
              </div>
              <div className="grabber62">
                <div className="grabber63">
                  <div className="parent2">
                    <div className="div136" style={{ fontFamily: "BOLD" }}>
                      {item.num}
                    </div>
                    <div className="score7">Score</div>
                  </div>
                </div>
              </div>
              <div className="trailing-accessories33">
                <div className="checkmark20">
                  {" "}
                  <img alt="" src="/img/vuesaxoutlinearrowright.svg" />
                </div>
              </div>
            </div>
            </div>
       );
       
      })}

   
          </div>
        </div>
      </div>
      <Header />
    </>
  );
};
