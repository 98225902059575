import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import editA from "../asset/img/editA.png";
import {Helmet} from "react-helmet";
import '../Main/mod.css'
import "../Main/ipc.css"

export const ToggleImg = () => {
  const [title, setTitle] = useState("");
  const [main_id, setMainid] = useState("");
  const [maintow_id, SetMaintow_id] = useState("");
  const [t_id, setT_id] = useState("");
  const [descs, setDescs] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
      const [editDescs, setEditDescs] = useState("");
      const [editId, setEditId] = useState(null); 

  const [isOpen, setIsOpen] = useState(true); 


  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  const openModal = (id, descs) => {
   
    setEditId(id);
    setEditDescs(descs);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditId(null);
    setEditDescs("");
  };

  
  const handleSubmit = async () => {
    try {

      axios
        .post("https://drp.academyxray.com/panelAdmin/Mainxtoggle",{
           title:title,
           t_id:t_id,
           main_id: main_id,
           maintow_id:maintow_id,
           descs:descs
        })
        .then((res) => {
          toast.success("اطلاعات با موفقیت ثبت شد");
        })
        .catch((err) => {
          toast.error("خطا در عکس بارگذاری شده");
          console.log("ERROR :" + err);
        });
    } catch (e) {
      toast.info("امکان ثبت دوباره وجود ندارد");
    }
  };

  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        "https://drp.academyxray.com/panelAdmin/listMaintoggle"
      );
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]); 
  
  const listDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDeletetoggle/${id}`)
      .then((response) => {
        setMainList((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const [field, setfield] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setfield(await res.clone().json());
    };
    getAllFiled();
  }, []);

  const [found, setFound] = useState([]);

  const getItemTree = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainTow/${id}`
      );
      setFound(await res.json());
    } catch (err) {}
  };

  const [foundR, setFoundR] = useState([]);

  const getItemTree2 = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainlistsAB/${id}`
      );
      setFoundR(await res.json());
    } catch (err) {}
  };

  const getItemTreeAll = async (id) => {
    try {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/listMaintoggle2/${id}`
      );
      setMainList(await res.json());
    } catch (err) {}
  };


  const [selectedId, setSelectedId] = useState(null); // نگه داشتن شناسه انتخاب شده

  const handleCheckboxChange = (e, id) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedId(id); // شناسه ردیف انتخاب شده
      getideall(id); // ارسال شناسه به متد getideall
    } else {
      setSelectedId(null); // پاک کردن شناسه انتخاب شده
      getideall(''); // ارسال یک مقدار خالی برای نشان دادن غیر انتخاب شدن
    }
  };

const [ide,setIde] = useState('');

const getideall = (idr) => {
  if (idr) {
    toast.success("ردیف انتخاب شد");
  } else {
    toast.info("ردیف از حالت انتخاب خارج شد");
  }
  setIde(idr); // شناسه را تنظیم می‌کنیم
  console.log(idr); // چاپ شناسه در کنسول
};

  const [file1, setfile1] = useState("");
  const [file2, setfile2] = useState("");
  const [file3, setfile3] = useState("");

  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };

  const handleFile2 = (e) => {
    setfile2(e.target.files[0]);
  };

  const handleFile3 = (e) => {
    setfile3(e.target.files[0]);
  };

  const updateImage1 = async () => {
    try {
      if(ide === null || ide === ''){
        toast.warning('هنوز ردیفی انتخاب نشده است')
      }else{
      if (!file1) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image1", file1);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update1e/${ide}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
  
    }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage2 = async () => {
    try {
      if(ide === null || ide === ''){
        toast.warning('هنوز ردیفی انتخاب نشده است')
      }else{
      if (!file2) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image2", file2);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update2e/${ide}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage3 = async () => {
    try {
      if(ide === null || ide === ''){
        toast.warning('هنوز ردیفی انتخاب نشده است')
      }else{
      if (!file3) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image3", file3);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update3e/${ide}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
       }
     }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };
  
  
  const updateTwo = async (id) => {
    try {
      await axios.put(`https://drp.academyxray.com/panelAdmin/updateTwoRE/${id}`, {
        descs: editDescs, // ارسال توضیحات ویرایش شده
      });
      toast.success("ویرایش با موفقیت انجام شد");
      closeModal(); // بسته شدن modal بعد از ذخیره تغییرات
    } catch (Err) {
      toast.error("خطا در ویرایش - لطفا دوباره سعی کنید");
    }
  };
  
  
  return (
    <>
   <Helmet>
      <title>محتوای دروس</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku5">
            <h4 className="pok">محتوای دروس</h4>
            <hr/>
 <div className="container-fluid">
      <div
        className="card-header d-flex justify-content-between align-items-center"
        style={{ cursor: "pointer" }}
        onClick={toggleSection}
      >
        <h5 className="mb-0 pok">ایجاد محتوای درسی - جستجو</h5>
        <span
          className="ms-auto"
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: isOpen ? "red" : "green",
          }}
        >
          {isOpen ? "-" : "+"}
        </span>
      </div>
      {isOpen && (
        <div className="row mb-2">
        <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                      id="selce"
                      style={{borderRadius:'20px'}}
                      className="form-control pok"
                      onChange={(e) => setMainid(e.target.value)}
                      onClick={() => getItemTree(main_id)}
                      name="main_id"
                    >
                      <option value={"23e0w"}>انتخاب کنید</option>
                      {field.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:'20px'}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => SetMaintow_id(e.target.value)}
                      onClick={() => getItemTree2(maintow_id)}
                      name=""
                    >
                      <option value={"0wss0"}>انتخاب کنید</option>
                      {found.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-xs-12 col-sm-3 mt-3">
                    <select
                    style={{borderRadius:'20px'}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setT_id(e.target.value)}
                      onClick={() => getItemTreeAll(t_id)}
                      name="t_id"
                    >
                      <option value={"0wss0"}>انتخاب کنید</option>
                      {foundR.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                    </select>
                  </div>

          <div className="col-xs-12 col-sm-12 mt-3 pok">
            <textarea
              style={{ borderRadius: "20px" }}
              rows={5}
              className="form-control"
              placeholder="توضیحات"
              onChange={(e) => setDescs(e.target.value)}
            />
          </div>

          <div className="container-fluid">
  <div className="row mb-4">
    {/* فایل اول: Show Problem */}
    <div className="col-xs-12 col-sm-4 mt-3">
      <label style={{ fontSize: "14px", fontWeight: "bold" }}>Show Problem</label>
      <input
        style={{ borderRadius: "20px" }}
        type="file"
        name="fileTwo"
        className="form-control"
        onChange={handleFile1}
      />
      <img
        src="/img/upload.png"
        width={"40px"}
        height={"40px"}
        className="mt-3 mr-2"
        style={{ cursor: "pointer" }}
        onClick={updateImage1}
      />
    </div>

    {/* فایل دوم: X-RAY */}
    <div className="col-xs-12 col-sm-4 mt-3">
      <label style={{ fontSize: "14px", fontWeight: "bold" }}>X-RAY</label>
      <input
        style={{ borderRadius: "20px" }}
        type="file"
        name="fileTwo"
        className="form-control"
        onChange={handleFile2}
      />
      <img
        src="/img/upload.png"
        width={"40px"}
        height={"40px"}
        className="mt-3 mr-2"
        style={{ cursor: "pointer" }}
        onClick={updateImage2}
      />
    </div>

    {/* فایل سوم: REAL */}
    <div className="col-xs-12 col-sm-4 mt-3">
      <label style={{ fontSize: "14px", fontWeight: "bold" }}>REAL</label>
      <input
        style={{ borderRadius: "20px" }}
        type="file"
        name="fileTwo"
        className="form-control"
        onChange={handleFile3}
      />
      <img
        src="/img/upload.png"
        width={"40px"}
        height={"40px"}
        className="mt-3 mr-2"
        style={{ cursor: "pointer" }}
        onClick={updateImage3}
      />
    </div>
  </div>
</div>
   <div className="col-xs-12 col-sm-3 mt-3 pok">
        <button onClick={handleSubmit}
          className="btn btn-outline-success"
          style={{ borderRadius: "20px" }}
        >
          ایجاد کردن
        </button>
      </div>
        </div>
      )}
    </div>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">انتخاب</th>
                      <th className="pok">ردیف</th>
                      <th className="pok">گروه اصلی</th>
                      <th className="pok">زیر گروه اول</th>
                      <th className="pok">زیر گروه دوم</th>
                      <th className="pok">تصویر</th>
                      <th className="pok">نمایش تست</th>
                      <th className="pok">ایجاد تست</th>
                      <th className="pok">ویرایش / حذف</th>
                    </tr>
                  </thead>
                  {mainList.map((item, index) => {
                    const { id, main_id, maintow_id, t_id } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                        <td className="pok">
                <input
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  checked={selectedId === id} // فقط چک‌باکس ردیف انتخاب شده تیک می‌خورد
                  onChange={(e) => handleCheckboxChange(e, id)} // تغییر وضعیت چک‌باکس
                />
              </td>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.main_id}</td>
                          <td className="pok">{item.maintow_id}</td>
                          <td className="pok">{item.t_id}</td>
                          <td>
                            <img
                              src={item.img}
                              width={"30px"}
                              height={"30px"}
                            />
                       <img
                              src={item.img2}
                              width={"30px"}
                              height={"30px"}
                              className="mr-1"
                            />
                          <img
                              src={item.img3}
                              width={"30px"}
                              height={"30px"}
                              className="mr-1"
                            />
                          </td>
                          <td className="pok">
                           <img src="/img/ok.png" height={'25px'} width={'25px'} />
                           <img src="/img/no.png" height={'25px'} width={'25px'} className="mr-1" />
                           <img src="/img/no.png" height={'25px'} width={'25px'} className="mr-1" />
                          </td>
                          <td className="pok">
                <Link className="pok" to='/panelAdmin/Question' state={{ main_id, maintow_id,t_id }}>تست جدید</Link>
                          </td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              /* onClick={() => updateTwo(id)} */
                              onClick={() => openModal(item.id, item.descs)} 
                            />
                            <img
                              src={del}
                              height="30px"
                              width="30px"
                              className="mr-3"
                              style={{ cursor: "pointer" }}
                              onClick={() => listDelete(id)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>


{isModalOpen && (
  <div className="modalR">
    <div className="modal-contentR">
      <h5 className="pok">ویرایش توضیحات</h5>
      <textarea
        value={editDescs}
        onChange={(e) => setEditDescs(e.target.value)}
        rows={5}
        className="form-control"
        placeholder="توضیحات"
      />
        <div className="modal-footerR">
          <button
            onClick={() => updateTwo(editId)}
            className="btn btn-primary pok"
          >
            ذخیره تغییرات
          </button>
          <button
            onClick={closeModal}
            className="btn btn-secondary pok"
          >
            بستن
          </button>
        </div>
    </div>
  </div>
)}

      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
