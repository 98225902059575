import React from 'react'

export const ReportAzmoon = () => {
  return (
    <>
    <body
          className="hold-transition sidebar-mini"
          style={{ backgroundImage: "none" }}
        >
          <div className="wrapper">
            <div className="content-wrapper pku4">
              <div className="content-header">
                <div className="container-fluid"></div>
           <div class="table-responsive">
                    <table class="table bg-white custom-rounded t-24 table-responsive">
                        <thead>
                            <tr>
                        <th className='pok'>شماره آزمون      </th>
                        <th className='pok'>گروه اصلی آزمون  </th>
                        <th className='pok'>کد کاربر         </th>
                        <th className='pok'>نام کاربر        </th>
                        <th className='pok'>نمره             </th>
                        <th className='pok'>تعداد سوالات درست </th>
                        <th className='pok'>تعداد سوالات غلط   </th>
                        <th className='pok'>لیست سوالات        </th>
                        <th className='pok'>گزینه درست        </th>
                        <th className='pok'>گزینه پاسخ کاربر </th>
                        <th className='pok'>درست/غلط	      </th>
                        <th className='pok'>خوانده شده/نشده   </th>
                        <th className='pok'>تاریخ</th>
                            </tr>
                        </thead>
                    </table>
            </div>
    </div>
       </div>
       </div>
</body>  
  
    </>
  )
}
