import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import {Helmet} from "react-helmet";
import "../Main/ipc.css"
import "../Main/sub.css"

export const MainTowx = () => {

  const [title, setTitle] = useState("");
  const [descs, setDescs] = useState("");
  const [main_id, setMainid] = useState("");

  const registerO = async () => {
    try {
      // ابتدا بررسی می‌کنیم که عنوان وارد شده باشد
      if (!title) {
        toast.info("لطفا تمامی موارد خواسته شده رو پر نمائید");
        return;
      }
  
      // ارسال اطلاعات به سرور برای ثبت
      if (file1.length <= 0) {
        // در صورتی که فایلی وجود نداشته باشد
        axios
          .post("https://drp.academyxray.com/panelAdmin/Mainxtow2", {
            title: title,
            main_id: main_id,
          })
          .then((res) => {
            toast.success("اطلاعات با موفقیت ثبت شد");
            // درخواست برای دریافت داده‌ها و به‌روزرسانی جدول
            fetchData(); // تابعی برای بارگذاری داده‌ها بعد از ثبت
          })
          .catch((err) => {
            toast.error("خطا در ارسال اطلاعات");
            console.log("Error :" + err);
          });
      } else {
        // در صورتی که فایل وجود داشته باشد
        const formdata = new FormData();
        formdata.append("title", title);
        formdata.append("main_id", main_id);
        formdata.append("image", file1);
  
        axios
          .post("https://drp.academyxray.com/panelAdmin/Mainxtow", formdata)
          .then((res) => {
            toast.success("اطلاعات با موفقیت ثبت شد");
            // درخواست برای دریافت داده‌ها و به‌روزرسانی جدول
            fetchData(); // تابعی برای بارگذاری داده‌ها بعد از ثبت
          })
          .catch((err) => {
            toast.error("خطا در ارسال اطلاعات");
            console.log("Error :" + err);
          });
      }
    } catch (e) {
      toast.info("امکان ثبت دوباره وجود ندارد");
      console.log("Error occurred: ", e);
    }
  };
  
  // تابع برای دریافت داده‌ها و به‌روزرسانی جدول
  const fetchData = async () => {
    try {
      const response = await axios.get("https://drp.academyxray.com/panelAdmin/listMain2");
      // فرض بر این است که داده‌ها به شکل زیر دریافت می‌شوند:
      setFiled(response.data); // فرض کنید setTableData تابعی است که داده‌های جدول را به‌روزرسانی می‌کند
    } catch (err) {
      console.log("Error fetching data: ", err);
    }
  };
  
 
   
  const [filed, setFiled] = useState([]);

  const handleDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/listDeletetow/${id}`)
      .then((response) => {
        setFiled((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  const [less, setless] = useState([]);

  useEffect(() => {
    const getAllFiled = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain");
      setless(await res.clone().json());
    };
    getAllFiled();
  }, [filed]);

useEffect(() =>{
  const listAll = async() =>{
  const res = await fetch(
    'https://drp.academyxray.com/panelAdmin/listMain2'
  );
  setFiled(await res.json());
  }
  listAll();
},[])

const updateTwo = async (id) => {
  try {
    await axios.put(`https://drp.academyxray.com/panelAdmin/updateTwo/${id}`, {
      title: editData.title,
      main_id: editData.main_id,
    });
    toast.success("ویرایش با موفقیت انجام شد");

    // پس از ویرایش داده‌ها، مجدداً داده‌های جدول را بارگذاری می‌کنیم
    const res = await fetch("https://drp.academyxray.com/panelAdmin/listMain2");
    setFiled(await res.json());  // داده‌های جدول را به‌روز می‌کند
  } catch (Err) {
    toast.error("خطا در ویرایش - لطفا دوباره سعی کنید");
  }
};


  const [file1, setfile1] = useState("");
  const [file2, setfile2] = useState("");
  const [file3, setfile3] = useState("");

  const handleFile1 = (e) => {
    setfile1(e.target.files[0]);
  };

  const handleFile2 = (e) => {
    setfile2(e.target.files[0]);
  };

  const handleFile3 = (e) => {
    setfile3(e.target.files[0]);
  };

  const updateImage1 = async () => {
    try {
      if (!file1) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image1", file1);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update1/${1}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage2 = async () => {
    try {
      if (!file2) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image2", file2);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update2/${1}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const updateImage3 = async () => {
    try {
      if (!file3) {
        toast.warning("لطفا یک عکس انتخاب کنید");
      } else {
        const formdata = new FormData();
        formdata.append("image3", file3);
        axios
          .put(`https://drp.academyxray.com/panelAdmin/update3/${1}`, formdata)
          .then((res) => {
            toast.success("عکس با موفقیت بارگذاری شد ");
          });
      }
    } catch (Err) {
      toast.error("خطا در ثبت عکس - لطفا دوباره سعی کنید");
    }
  };

  const [loading,setLoading] = useState([])
  useEffect(() => {
    const loadImage = async() => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/loadingImageTree");
      setLoading(await res.clone().json());
    }
    loadImage();
  },[])

const [search,setSearch] = useState('')
const [menr,setMenr] = useState('')

const [isOpen, setIsOpen] = useState(true);

const toggleSection = () => setIsOpen(!isOpen);

const [isModalOpen, setIsModalOpen] = useState(false);
const [editData, setEditData] = useState('');

const openEditModal = (item) => {
  setEditData({ id: item.id, title: item.title, main_id: item.main_id });
  setIsModalOpen(true);
};

const closeEditModal = () => {
  setIsModalOpen(false);
};


return (
    <>
    <Helmet>
      <title>زیر گروه اول</title>
    </Helmet>
      <body className="hold-transition sidebar-mini">
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku3">

            <div className="accordion-container">
  <div className="accordion-body mt-3">

    <div className="card shadow-sm mt-4">
      <div
        className="card-header d-flex justify-content-between align-items-center"
        style={{ cursor: "pointer" }}
        onClick={toggleSection}
      >
        <h5 className="mb-0 pok">ایجاد گروه - جستجو</h5>
        <span
          className="ms-auto"
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: isOpen ? "red" : "green",
          }}
        >
          {isOpen ? "-" : "+"}
        </span>
      </div>
      {isOpen && (
        <div className="card-body">
          <div className="container-fluid">
            <div className="row mb-2">
            <div class="col-xs-12 col-sm-3 mt-3">
                    <select style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok"
                      onChange={(e) => setMainid(e.target.value)}
                      name="fieldID"
                    >
                      <option style={{borderRadius:"20px"}} className="pok" value={"23e0w"}>انتخاب کنید</option>
                      {less.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                      <option value={"all"}>All</option>
                    </select>
                  </div>

              <div className="col-xs-12 col-sm-3 mt-3">
              <input
                  type="text"
                  className="form-control pok"
                  placeholder="عنوان"
                  onChange={(e) => setTitle(e.target.value)} 
                  style={{ borderRadius: "20px" }}
                />

              </div>

              <div className="col-xs-12 col-sm-3 mt-3">
                <button
                  style={{ borderRadius: "20px" }}
                  className="btn btn-outline-success pok"
                  onClick={registerO}
                >
                  ایجاد کردن
                </button>
              </div>
            </div>
          </div>
          <hr />
          <p className="pok">جستجو</p>
      <div className="container-fluid">
                <div className="row mb-2">
         <select style={{borderRadius:"20px"}}
                      id="selce"
                      className="form-control pok col-sm-3"
                      onChange={(e) => setMenr(e.target.value)}
                      name="menr"
                    >
                      <option style={{borderRadius:"20px"}} className="pok" value={"23e0w"}>جستجو گروه</option>
                      {less.map((item) => {
                        return <option value={item.title}>{item.title}</option>;
                      })}
                      <option value={"all"}>All</option>
                    </select>
                        <p className="pok mt-2 mr-2"> جستجو براساس عنوان : </p>
               <input type="text" className="form-control col-sm-3 mt-1 mr-1 pok" style={{ borderRadius: "20px" }} onChange={(e) => setSearch(e.target.value)}/>
                </div>
                </div>
        </div>
        
      )}

    </div>
            <hr />
            <div className="container-fluid">
              <div className="row mb-4">
            <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile1}
                />
              </div>
              <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3" style={{cursor:'pointer'}} onClick={updateImage1}/>
              
              <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile2}
                />
              </div>
              <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3" style={{cursor:'pointer'}} onClick={updateImage2} />
              <div class="col-xs-12 col-sm-3 mt-3">
                <input
                style={{borderRadius:"20px"}}
                  type="file"
                  name="fileTwo"
                  className="form-control"
                  onChange={handleFile3}
                />
              </div>
              <img src='/img/upload.png' width={'40px'} height={'40px'} className="mt-3" style={{cursor:'pointer'}} onClick={updateImage3} />
</div>
            </div>

              {filed.map((item, index) => {
                const { id } = item;
                return item.img != null ? (
                  <div class="col-xs-12 col-sm-5 mt-3">
                    {" "}
                   {/*  <img src={item.img} width={"100px"} height={"100px"} /> */}
                  </div>
                ) : null;
              })}
{loading.map((item) => {
        return(              
          
       <div className="container-fluid">
        <div className="row mb-4">
        <label className="pok mt-4" style={{marginTop:"10px", color:"green"}}>
                      اندازه پیشنهادی:<span style={{color:"red"}} className="pok">280*550</span>
        </label>
              <div class="col-xs-12 col-sm-1 mt-3">
                  <img src={item.img1} width={'50px'} height={'50px'} style={{borderRadius:"50%"}}/>
                  </div>
                   <div class="col-xs-12 col-sm-1 mt-3">
                  <img src={item.img2} width={'50px'} height={'50px'} style={{borderRadius:"50%"}}/>
                  </div>
                 <div class="col-xs-12 col-sm-1 mt-3">
                  <img src={item.img3} width={'50px'} height={'50px'} style={{borderRadius:"50%"}}/>
              </div>        
         </div>
         </div>
    )
  })
}

 </div>
 </div>
              
              <hr />
         <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                    <th className="pok">گروه اصلی</th>
                    <th className="pok">عنوان</th>
                   <th className="pok">ویراش / حذف</th>
                    </tr>
                  </thead>
                  {filed
                        .filter((item) => {
                          const matchesSearch = search
                            ? item.title.toLowerCase().includes(search.toLowerCase())
                            : true;

                          const matchesMenr =
                            menr === "all" || menr === ""
                              ? true
                              : item.main_id && item.main_id.toLowerCase().includes(menr.toLowerCase());

                          return matchesSearch && matchesMenr;
                        })
                        .map((item, index) => {
                          const { id } = item;
                          return (
                            <tbody
                              class="table-group-divider table-divider-color"
                              key={item.id}
                            >
                              <tr>
                                <td className="pok">{item.main_id}</td>
                                <td className="pok">{item.title}</td>
                                <td>
                                  <img
                                    src={edit}
                                    height="30px"
                                    width="30px"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openEditModal(item)}
                                    /* onClick={() => updateTwo(id)} */
                                  />
                                  <img
                                    src={del}
                                    height="30px"
                                    width="30px"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDelete(id)}
                                    className="mr-3"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                </table>
              </div>
            
            </div>
          </div>
        </div>

        {isModalOpen && (
  <div className="modal-overlayr">
    <div className="modal-contentr">
      <h3 className="modal-headerr pok">ویرایش اطلاعات</h3>
      <div className="modal-bodyr pok">
        <input
          type="text"
          className="form-control mt-2"
          placeholder="عنوان را وارد کنید"
          value={editData.title}
          onChange={(e) =>
            setEditData((prev) => ({ ...prev, title: e.target.value }))
          }
        />
        <select
          className="form-control mt-3"
          value={editData.main_id}
          onChange={(e) =>
            setEditData((prev) => ({ ...prev, main_id: e.target.value }))
          }
        >
          <option value="" className="pok">انتخاب گروه اصلی</option>
          {less.map((group) => (
            <option key={group.title} value={group.title}>
              {group.title}
            </option>
          ))}
        </select>
      </div>
      <div className="modal-footerr">
        <button
          className="btn btn-primary pok"
          onClick={() => {
            updateTwo(editData.id);
            closeEditModal();
          }}
        >
          ذخیره
        </button>
        <button className="btn btn-danger ml-3 pok" onClick={closeEditModal}>
          لغو
        </button>
      </div>
    </div>
  </div>
)}


      </body>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
