import React, { useEffect, useState } from "react";
import "../Header/haeder.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const Header = () => {
  const location = useLocation();
  const nav = useNavigate();
  const title = location.state.title;
  const name = location.state.name;
  const userId = location.state.userId;
  const onv = location.state.onv;
  const gru = location.state.gru;

  const gotopage = (title, name,userId) => {
    nav("/Overview", { state: { title, name,userId } });
  };

  const backToMain = () => {
    nav("/Mainto", { state: { title, name,userId,onv,gru } });
  };

  const [showBR14, setShowBR14] = useState(false);
  const handleCloseBR14 = () => setShowBR14(false);
  const handleShowBR14 = () => setShowBR14(true);

  const showHandelpopsocial = () => {
    handleShowBR14(true);
   
  };

  const hidePop = () => {
    handleCloseBR14(true);
  };
  
  return (
    <>
      <div class="profile4">Exam</div>
      <div class="swatch-parent60" onClick={() => gotopage(title, name,userId)}>
        <img alt="" src="/img/Group 5551.png" />
      </div>
      <div class="swatch-parent62" onClick={showHandelpopsocial}>
        <img alt="" src="/img/Group 5550.png" />
      </div>
      <div class="swatch-parent63">
        <div class="swatch101"></div>
        <img class="person-icon22" alt="" src="/img/person.svg" />
      </div>

      <Modal show={showBR14} onHide={handleCloseBR14}>
        <div class="pop-up-exitK" dir="ltr">
          <div class="swatch-parent19K">
            <div class="swatch48K"></div>
            <div class="do-you-wantK" style={{ color: "white" }}>
              Do you want to Exit The Exam ?
            </div>
            <div class="sign-in-btn30K">
              <div class="button-text30K mr-1" onClick={hidePop}>
                <div class="falseK" style={{ color: "white" }}>
                  Cancel
                </div>
              </div>
              <div
                class="button-text31K mr-1"
                onClick={() => backToMain(name, onv)}
              >
                <div class="falseK" style={{ color: "white" }}>
                  Ok
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
};
