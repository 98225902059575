import React from 'react'

export const ExitProgram = () => {
  return (
        <>
              <img className='imgload' src='/img/load.png' />

<div className='ll' style={{zIndex:"99", textAlign:'center'}}>
</div>
       </>
  )
}

