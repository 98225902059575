import React from "react";
import "../OverView/AboutUs.css";
import "../OverView/global.css";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../Header/Header";
import { useLocation } from "react-router-dom";

export const About = () => {
  const location = useLocation();
  const nav = useNavigate();
  const onv = location.state.onv;
  const name = location.state.name;
  const userId = location.state.userId;

  return (
    <>
    
      <div className="about-usX" dir="ltr">
        <div className="swatch99X" />
        <div className="swatch100X">
          <br />
          <div className="lorem-ipsum-dolor5X">
            Lorem ipsum dolor sit amet consectetur. Pellentesque vestibulum
            suscipit orci est aliquet amet. Quis ac sit dictum nec. Mollis mi
            quisque suscipit ultrices at tellus tempus purus vestibulum.
            Maecenas nisi feugiat venenatis viverra suscipit ut.Lorem ipsum
            dolor sit amet consectetur. Pellentesque vestibulum suscipit orci
            est aliquet amet. Quis ac sit dictum nec. Mollis mi quisque suscipit
            ultrices at tellus tempus purus vestibulum. Maecenas nisi feugiat
            venenatis viverra suscipit ut. Lorem ipsum dolor sit amet
            consectetur. Pellentesque vestibulum suscipit orci est aliquet amet.
            Quis ac sit dictum nec. Mollis mi quisque suscipit ultrices at
            tellus tempus purus vestibulum. Maecenas nisi feugiat venenatis
            viverra suscipit ut.Lorem ipsum dolor sit amet consectetur.
            Pellentesque vestibulum suscipit orci est aliquet amet. Quis ac sit
            dictum nec. Mollis mi quisque suscipit ultrices at tellus tempus
            purus vestibulum. Maecenas nisi feugiat venenatis viverra suscipit
            ut. Lorem ipsum dolor sit amet consectetur. Pellentesque vestibulum
            suscipit orci est aliquet amet. Quis ac sit dictum nec. Mollis mi
            quisque suscipit ultrices at tellus tempus purus vestibulum.
            Maecenas nisi feugiat venenatis viverra suscipit ut.Lorem ipsum
            dolor sit amet consectetur. Pellentesque vestibulum suscipit orci
            est aliquet amet. Quis ac sit dictum nec. Mollis mi quisque suscipit
            ultrices at tellus tempus purus vestibulum. Maecenas nisi feugiat
            venenatis viverra suscipit ut. Lorem ipsum dolor sit amet
            consectetur. Pellentesque vestibulum suscipit orci est aliquet amet.
            Quis ac sit dictum nec. Mollis mi quisque suscipit ultrices at
            tellus tempus purus vestibulum. Maecenas nisi feugiat venenatis
            viverra suscipit ut.Lorem ipsum dolor sit amet consectetur.
            Pellentesque vestibulum suscipit orci est aliquet amet. Quis ac sit
            dictum nec. Mollis mi quisque suscipit ultrices at tellus tempus
            purus vestibulum. Maecenas nisi feugiat venenatis viverra suscipit
            ut. Lorem ipsum dolor sit amet consectetur. Pellentesque vestibulum
            suscipit orci est aliquet amet. Quis ac sit dictum nec. Mollis mi
            quisque suscipit ultrices at tellus tempus purus vestibulum.
            Maecenas nisi feugiat venenatis viverra suscipit ut.Lorem ipsum
            dolor sit amet consectetur. Pellentesque vestibulum suscipit orci
            est aliquet amet. Quis ac sit dictum nec. Mollis mi quisque suscipit
            ultrices at tellus tempus purus vestibulum. Maecenas nisi feugiat
            venenatis viverra suscipit ut. Lorem ipsum dolor sit amet
            consectetur. Pellentesque vestibulum suscipit orci est aliquet amet.
            Quis ac sit dictum nec. Mollis mi quisque suscipit ultrices at
            tellus tempus purus vestibulum. Maecenas nisi feugiat venenatis
            viverra suscipit ut.Lorem ipsum dolor sit amet consectetur.
            Pellentesque vestibulum suscipit orci est aliquet amet. Quis ac sit
            dictum nec. Mollis mi quisque suscipit ultrices at tellus tempus
            purus vestibulum. Maecenas nisi feugiat venenatis viverra suscipit
            ut. Lorem ipsum dolor sit amet consectetur. Pellentesque vestibulum
            suscipit orci est aliquet amet. Quis ac sit dictum nec. Mollis mi
            quisque suscipit ultrices at tellus tempus purus vestibulum.
            Maecenas nisi feugiat venenatis viverra suscipit ut.Lorem ipsum
            dolor sit amet consectetur. Pellentesque vestibulum suscipit orci
            est aliquet amet. Quis ac sit dictum nec. Mollis mi quisque suscipit
            ultrices at tellus tempus purus vestibulum. Maecenas nisi feugiat
            venenatis viverra suscipit ut. Lorem ipsum dolor sit amet
            consectetur. Pellentesque vestibulum suscipit orci est aliquet amet.
            Quis ac sit dictum nec. Mollis mi quisque suscipit ultrices at
            tellus tempus purus vestibulum. Maecenas nisi feugiat venenatis
            viverra suscipit ut.Lorem ipsum dolor sit amet consectetur.
            Pellentesque vestibulum suscipit orci est aliquet amet. Quis ac sit
            dictum nec. Mollis mi quisque suscipit ultrices at tellus tempus
            purus vestibulum. Maecenas nisi feugiat venenatis viverra suscipit
            ut. Lorem ipsum dolor sit amet consectetur. Pellentesque vestibulum
            suscipit orci est aliquet amet. Quis ac sit dictum nec. Mollis mi
            quisque suscipit ultrices at tellus tempus purus vestibulum.
            Maecenas nisi feugiat venenatis viverra suscipit ut.Lorem ipsum
            dolor sit amet consectetur. Pellentesque vestibulum suscipit orci
            est aliquet amet. Quis ac sit dictum nec. Mollis mi quisque suscipit
            ultrices at tellus tempus purus vestibulum. Maecenas nisi feugiat
            venenatis viverra suscipit ut.
          </div>
        </div>
        <div className="segmented-control4X">
          <div className="button-14X">
            <div className="div103X">􀓔</div>
            <Link to="/Overview" state={{ name, onv,userId }}>
              <div className="label27X">Overview</div>
            </Link>
          </div>
          <div className="button-14X">
            <div className="div103X">􀓔</div>
            <Link to="/Analyze" state={{ name, onv,userId }}>
              <div className="label27X">Analyze</div>
            </Link>
          </div>
          <div className="button-14X">
            <div className="div103X">􀓔</div>
            <Link to="/Exam" state={{ name, onv,userId }}>
              <div className="label27X">Exam</div>
            </Link>
          </div>
          <div className="button-34X">
            <div className="div105X">􀓔</div>
            <div className="label29X">About</div>
          </div>
        </div>
        
        <div className="swatch-parent61X">
          <div className="swatch102X" />
          <div className="about-us1X">About Us</div>
        </div>
        <div className="swatch-parent63X">
          <div className="swatch101X" />
          <img className="person-icon22X" alt="" src="./public/person.svg" />
        </div>
      </div>

      <Header />
    </>
  );
};
