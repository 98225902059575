import React from "react";
import "../OverView/Overview.css";
import "../OverView/global.css";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../Header/Header";
import { useLocation } from "react-router-dom";

export const Overview = () => {
  const location = useLocation();
  const nav = useNavigate();
  const onv = location.state.onv;
  const name = location.state.name;
  const userId = location.state.userId;
  const gru = location.state.gru;

  const UserName = localStorage.getItem('userId')

  return (
    <>
      <div className="overviewRT" dir="ltr">
        <div className="swatch63RT" />
        <div className="swatch-parent28RT">
          <div className="swatch64RT" />
          <div className="overview1RT">Overview</div>
        </div>

        <div className="swatch-parent31RT">
          <div className="swatch67RT">
            <img
              alt=""
              className="personbadgeclock-iconRT"
              src="/img/personbadgeclock.svg"
            />
            <div className="minRT">
            <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}> 563 Min </span>
            </div>
            <div className="study-timeRT">Study time</div>
          </div>
        </div>
        <div className="swatch-parent32RT">
          <div className="swatch67RT" />
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/menucard.svg"
          />
          <div className="div39RT">
           <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}> 100 / 1000 </span>
          </div>
          <div className="read-pageRT">Read Page</div>
        </div>
        <div className="swatch-parent33RT">
          <div className="swatch67RT" />
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/capsuleinsetfilled.svg"
          />
          <div className="div40RT">
           <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}> 70% </span>
          </div>
          <div className="answered-easy-qs1RT">Answered Easy Qs.</div>
        </div>
        <div className="swatch-parent34RT">
          <div className="swatch67RT" />
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/capsuleinsetfilled1.svg"
          />
          <div className="div41RT">
           <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}> 50% </span>
          </div>
          <div className="answered-easy-qs1RT">Answered Medium Qs.</div>
        </div>
        <div className="swatch-parent35RT">
          <div className="swatch67RT" />
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/capsuleinsetfilled2.svg"
          />
          <div className="div41RT">
          <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}>30% </span>
          </div>
          <div className="answered-easy-qs1RT">Answered Hard Qs.</div>
        </div>
        
        <div className="swatch-parent36RT">
        <div className="swatch67RT" />
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/capsuleinsetfilled2.svg"
          />
          <div className="div41RT">
          <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}>80 / 150 </span>
          </div>
          <div className="answered-easy-qs1RT">Total Answered Qs.</div>

        </div>
                
        <div className="swatch-parent45RT">
        <div className="swatch67RT" />
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/person4.svg"
          />
          <div className="div41RT" >
          <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}>{UserName}</span>
          </div>
          <div style={{marginTop:"-2px"}} className="answered-easy-qs1RT">Name</div>

        </div>
        <div className="swatch-parent38RT">
        <div className="swatch67RT" />
          <div className="div43RT">
          <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}>90</span>
          </div>
          <div className="answered-easy-qs1RT">Maximum score</div>
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/listbulletrectangleportrait.svg"
          />
        </div>
        <div className="swatch-parent39RT">
          <div className="swatch67RT" />
          <div className="div44RT">
          <span style={{opacity:"0"}}>1</span> <span style={{ fontFamily: "BOLD" }}> 83</span>
           
          </div>
          <div className="answered-easy-qs1RT">Average score</div>
          <img
            alt=""
            className="personbadgeclock-iconRT"
            src="/img/doctextimage.svg"
          />
        </div>

        <div className="segmented-controlRT">
          <div className="button-1RT">
            <div className="div36RT">􀓔</div>
            <div className="label15RT">Overview</div>
          </div>
          <div className="button-2RT">
            <div className="div37RT">􀓔</div>
            <Link to="/Analyze" state={{ name, onv,userId }}>
              <div className="label16RT">Analyze</div>
            </Link>
          </div>
          <div className="button-2RT">
            <div className="div37RT">􀓔</div>
            <Link to="/Exam" state={{ name, onv,userId }}>
              <div className="label16RT">Exam</div>
            </Link>
          </div>
          <div className="button-2RT">
            <div className="div37RT">􀓔</div>
            <Link to="/About" state={{ name, onv,userId }}>
              <div className="label16RT">About</div>
            </Link>
          </div>
        </div>
      </div>
      <Header />
    </>
  );
};
