import React from 'react'

export const ReportUserAll = () => {
  return (
   <>
    <body
          className="hold-transition sidebar-mini"
          style={{ backgroundImage: "none" }}
        >
          <div className="wrapper">
            <div className="content-wrapper pku4">
              <div className="content-header">
                <div className="container-fluid"></div>
           <div class="table-responsive">
                    <table class="table bg-white custom-rounded t-24 table-responsive">
                        <thead>
                            <tr class="text-center">
                            <th className='pok'>کد کاربر                 </th>
                            <th className='pok'>نام کاربر                </th>
                            <th className='pok'>تاریخ                    </th>
                            <th className='pok'>عضویت                    </th>
                            <th className='pok'>زمان کل مطالعه           </th>
                            <th className='pok'>تعداد صفحات خوانده شده   </th>
                            <th className='pok'>تعداد صفحات مرور کرده    </th>
                            <th className='pok'>زمان مطالعه دروس مرور شده</th>
                            <th className='pok'>تعداد کل آزمون ها        </th>
                            <th className='pok'>میانگین نمرات            </th>
                            <th className='pok'>بالاترین نمره             </th>
                            <th className='pok'>پایین ترین نمره          </th>
                            <th className='pok'>تعداد تست درست           </th>
                            <th className='pok'>تعداد تست غلط	         </th>
                            <th className='pok'>تعداد تست خوانده شده درست</th>
                            <th className='pok'>تعداد تست نخوانده غلط</th>
                            </tr>
                        </thead>
                    </table>
            </div>
    </div>
       </div>
       </div>
   </body>

   </>
  )
}
