import React, { useState } from "react";
import "../OverView/Analyze2.css";
import "../OverView/global.css";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../Header/Header";
import { useLocation } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';

export const Analyze = () => {
  const location = useLocation();
  const nav = useNavigate();

  const onv = location.state.onv;
  const name = location.state.name;
  const userId = location.state.userId;


  const data = [
    { value: '20', label: 'T-Unsudied',color: '#229954' },
    { value: '30', label: 'F-Sudied',color:'#bc2707' },
    { value: '40', label: 'F-Unsudied',color: '#f33e17' },
    { value: '50', label: 'T-Sudied',color:'#145a32' },
  ];
  
  const size = {
    height: 170,
  };


const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  '1',
  '10',
  '20',
  '30',
  '40',
  '50',
  'Qty',
];

const [fit1,setFit1] = useState('A');
const [fit2,setFit2] = useState('B');
const [fit3,setFit3] = useState('C');
const [fit4,setFit4] = useState('D');
const [fit5,setFit5] = useState('E');
const [fit6,setFit6] = useState('F');

const [arm,setArm] = useState('A');

const handelSubmit = (logic) =>{
    setArm(null)
    setArm(logic)
    console.log(arm)
}

  return (
    <>
      <div className="analyze1" dir="ltr" style={{fontFamily:'sans-serif'}}>
        <div className="swatch93" />
        <div className="swatch-parent556 scrollbar">
        <div className="swatch-parent55">
          <div className="swatch94" />
          <div className="progress-chart1">Study Time</div>
          
          <div className="charttt">
          <div className="rg-scroller">sd</div>
          <br />

{  arm === 'A' ? (
        <div className="segmented-control33">
        <div className="button-29" onClick={() =>handelSubmit(fit1)}>
            <div className="div95">􀓔</div>
              <div className="label25">1W</div>
          </div>
          <div className="button-13" onClick={() =>handelSubmit(fit2)}>
            <div className="div99">􀓔</div>
              <div className="label29">2W</div>
          </div>
          <div className="button-13" onClick={() =>handelSubmit(fit3)}>
            <div className="div99">􀓔</div>
              <div className="label29">1M</div>
          </div>
          <div className="button-13" onClick={() =>handelSubmit(fit4)}>
            <div className="div99">􀓔</div>
              <div className="label29">3M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit5)}>
            <div className="div99">􀓔</div>
              <div className="label29">6M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit6)}>
            <div className="div99">􀓔</div>
              <div className="label29">1Y</div>
          </div>
        </div>
      ) : null}

{  arm === 'B' ? (    
  <div className="segmented-control33">
          <div className="button-13" onClick={() =>handelSubmit(fit1)}>
            <div className="div99">􀓔</div>
              <div className="label29">1W</div>
          </div>

          <div className="button-29" onClick={() =>handelSubmit(fit2)}>
            <div className="div95">􀓔</div>
              <div className="label25">2W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit3)}>
            <div className="div99">􀓔</div>
              <div className="label29">1M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit4)}>
            <div className="div99">􀓔</div>
              <div className="label29">3M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit5)}>
            <div className="div99">􀓔</div>
              <div className="label29">6M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit6)}>
            <div className="div99">􀓔</div>
              <div className="label29">1Y</div>
          </div>
        </div>
 ) : null}

 {  arm === 'C' ? (    
  <div className="segmented-control33">
          <div className="button-13" onClick={() =>handelSubmit(fit1)}>
            <div className="div99">􀓔</div>
              <div className="label29">1W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit2)}>
            <div className="div99">􀓔</div>
              <div className="label29">2W</div>
          </div>

          <div className="button-29" onClick={() =>handelSubmit(fit3)}>
            <div className="div95">􀓔</div>
              <div className="label25">1M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit4)}>
            <div className="div99">􀓔</div>
              <div className="label29">3M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit5)}>
            <div className="div99">􀓔</div>
              <div className="label29">6M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit6)}>
            <div className="div99">􀓔</div>
              <div className="label29">1Y</div>
          </div>
        </div>
 ) : null}

 {  arm === 'D' ? (    
  <div className="segmented-control33">
          <div className="button-13" onClick={() =>handelSubmit(fit1)}>
            <div className="div99">􀓔</div>
              <div className="label29">1W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit2)}>
            <div className="div99">􀓔</div>
              <div className="label29">2W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit3)}>
            <div className="div99">􀓔</div>
              <div className="label29">1M</div>
          </div>

          <div className="button-29" onClick={() =>handelSubmit(fit4)}>
            <div className="div95">􀓔</div>
              <div className="label25">3M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit5)}>
            <div className="div99">􀓔</div>
              <div className="label29">6M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit6)}>
            <div className="div99">􀓔</div>
              <div className="label29">1Y</div>
          </div>
        </div>
 ) : null}
 
 {arm === 'E' ? (    
  <div className="segmented-control33">
          <div className="button-13" onClick={() =>handelSubmit(fit1)}>
            <div className="div99">􀓔</div>
              <div className="label29">1W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit2)}>
            <div className="div99">􀓔</div>
              <div className="label29">2W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit3)}>
            <div className="div99">􀓔</div>
              <div className="label29">1M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit4)}>
            <div className="div99">􀓔</div>
              <div className="label29">3M</div>
          </div>

          <div className="button-29" onClick={() =>handelSubmit(fit5)}>
            <div className="div95">􀓔</div>
              <div className="label25">6M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit6)}>
            <div className="div99">􀓔</div>
              <div className="label29">1Y</div>
          </div>
        </div>
 ) : null}

 {arm === 'F' ? (    
  <div className="segmented-control33">
          <div className="button-13" onClick={() =>handelSubmit(fit1)}>
            <div className="div99">􀓔</div>
              <div className="label29">1W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit2)}>
            <div className="div99">􀓔</div>
              <div className="label29">2W</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit3)}>
            <div className="div99">􀓔</div>
              <div className="label29">1M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit4)}>
            <div className="div99">􀓔</div>
              <div className="label29">3M</div>
          </div>

          <div className="button-13" onClick={() =>handelSubmit(fit5)}>
            <div className="div99">􀓔</div>
              <div className="label29">6M</div>
          </div>

          <div className="button-29" onClick={() =>handelSubmit(fit6)}>
            <div className="div95">􀓔</div>
              <div className="label25">1Y</div>
          </div>
        </div>
 ) : null}

   <BarChart 
      series={[      
        { data: [3, 4, 1, 6, 5,7], stack: 'A',color:'#21cab1' },
        { data: [4, 3, 1, 5, 8,2], stack: 'A',color:'#94d13d' },
        { data: [0], stack: 'A',color:'#94d13d' },
        { data: [0], stack: 'A',color:'#94d13d' },
        { data: [0], stack: 'A',color:'#94d13d' },
      ]}
      height={290}

      xAxis={[{
        data: ['1', '2','3','4','5','6','Days'], scaleType: 'band' }]}
    />
  <div className="average-parent">
    <div className="average1">
      New
    </div>
    <div className="group-child6" />
    <div className="score5">
      Review
    </div>
    <div className="group-child7" />
  </div>
    </div>
    
        </div>

        <div className="swatch-parent567">
          <div className="swatch94" />
          <div className="study-result">Progress Chart</div>
          <div className="chartt1">
          <p></p>
  <LineChart
      height={300}
      className="ml-4"
      series={[
        { data: pData,color:'#94d13d' },
        { data: uData,color:'#21cab1' },
      ]}
        xAxis={[{ scaleType: 'point', data: xLabels }]}
        yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
    />
          </div>
          <div className="average-parent1">
    <div className="average1">
      Average
    </div>
    <div className="group-child6" />
    <div className="score5">
      Score
    </div>
    <div className="group-child7" />
  </div>
        </div>
        <div className="swatch-parent56">
          <div className="swatch94" />
          <div className="study-result">Stody Result</div>
          <div className="chartt11">
<PieChart
      series={[
        {
          arcLabel: (item) => `%${item.value}`,
          arcLabelMinAngle: 45,
          data,
        },
        
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontWeight: 'bold',
        },
      }}
      {...size}
    />
    </div>
        </div>
        </div>


        <div className="segmented-control3">
          <div className="button-13">
            <div className="div94">􀓔</div>
            <Link to="/Overview" state={{ name, onv,userId }}>
              <div className="label24">Overview</div>
            </Link>
          </div>
          <div className="button-23">
            <div className="div95">􀓔</div>
            <div className="label25">Analyze</div>
          </div>
          <div className="button-13">
            <div className="div94">􀓔</div>
            <Link to="/Exam" state={{ name, onv,userId }}>
              <div className="label24">Exam</div>
            </Link>
          </div>
          <div className="button-13">
            <div className="div94">􀓔</div>
            <Link to="/About" state={{ name, onv,userId }}>
              <div className="label24">About</div>
            </Link>
          </div>
        </div>
      </div>
      
      <Header />
    </>
  );
};
