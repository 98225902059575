import { Header } from "../Header/Header";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "jalali-moment";
import axios from "axios";
import "../Result/ResultTest.css";
import { Head } from "../Header/Head";

export const ResultTest = () => {
  const location = useLocation();
  const nav = useNavigate();

  const onv = location.state.onv;
  const name = location.state.name;
  const token = location.state.token;
  const num = location.state.npage;
  const trueT = location.state.trueT;
  const falseF = location.state.falseF;
  const userId = location.state.userId;
  const [finde, setFinde] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchOne/${token}`
      );
      setFinde(await res.json());
    };
    getAllProductsRT();
  }, []);

  const gotopage = async(name, onv) => {
    await axios.post("https://drp.academyxray.com/paneladmin/saveResult",{
        onv:onv,
        name:name ,
        token:token, 
        num:num,
        trueT:trueT,
        falseF:falseF,
        date: moment(new Date(), "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"),
    }).then(() =>{
      nav("/Mainto", { state: { name, onv } });
    })
    
  };

  return (
    <>
      <div className="pop-up-exit1J">
        <div className="swatch-parent20J">
          <div className="swatch49J" />
          <div className="result-testJ">Result Test</div>
          <div className="swatch-parent21J">
            <div className="swatch50J" />
            <div className="score1J mt-1">Score</div>
          </div>
          <div className="swatch-parent22J">
            <div className="swatch50J" />
            <div className="result1J mt-1">Result</div>
          </div>
          <div
            className="swatch52J"
            style={{
              marginTop: "75px",
            }}
          />
          <div
            className="swatch53J"
            style={{
              marginTop: "-150px",
            }}
          >
            <div
              style={{
                marginTop: "5px",
                width: "50%",
              }}
            >
              False
            </div>{" "}
            <div
              style={{
                marginTop: "5px",
                width: "50%",
                fontFamily: "BOLD",
              }}
            >
              {falseF} / Question
            </div>
          </div>
          <div
            className="swatch54J"
            style={{
              marginTop: "-30px",
            }}
          >
            <div
              style={{
                marginTop: "5px",
                width: "50%",
                fontFamily: "BOLD",
              }}
            >
              True
            </div>{" "}
            <div
              style={{
                marginTop: "5px",
                width: "50%",
                fontFamily: "BOLD",
              }}
            >
              {trueT+1} / Question
            </div>
          </div>
          <div className="div28J">{num}</div>
          <div className="sign-in-btn32J">
            <div className="button-text32J">
              <div className="ok1J" onClick={() => gotopage(name, onv)}>
                Ok
              </div>
            </div>
          </div>
        </div>
      </div>
      <Head />
    </>
  );
};
