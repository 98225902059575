import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import del from "../asset/img/del.png";
import edit from "../asset/img/edit2.png";
import {Helmet} from "react-helmet";
import moment from "jalali-moment";

export const Users = () => {
  const [title, setTitle] = useState("");
  const [file1, setfile1] = useState("");

  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  
  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/userlist");
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]);

  const listDelete = (id) => {
    axios
      .delete(`https://drp.academyxray.com/panelAdmin/deleteUsers/${id}`)
      .then((response) => {
        setMainList((values) => {
          return values.filter((item) => item.id !== id);
        });
        toast.success("حذف  با موفقیت انجام شد");
      });
  };

  return (
    <>
    <Helmet>
      <title>لیست کاربران</title>
    </Helmet>

      <body
        className="hold-transition sidebar-mini"
        style={{ backgroundImage: "none" }}
      >

        <div className="wrapper">
          <div className="content-wrapper">
            <div className="content-header pku3">
            <h4 className="pok">لیست کاربران</h4>
            <hr />
            <div class="col-xs-12 col-sm-6 mt-3">
              <p className="pok">جستجو براساس نام و نام خانوادگی</p>
                    <input
                    style={{borderRadius:"20px" , textAlign: "center"}}
                      id="searchInput"
                      type="text"
                      className="form-control col-sm-6 pok"
                     
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <p></p>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">کد یکتا</th>
                      <th className="pok">نام</th>
                      <th className="pok">تاریخ عضویت</th>
                      <th className="pok">ویرایش/حذف</th>
                    </tr>
                  </thead>
                  {mainList 
                    .filter((item) => {
                      return searchTerm.toLowerCase() === ""
                        ? item
                        : item.name.toLowerCase().includes(searchTerm);
                    })
                  .map((item, index) => {
                    const { id, title, img } = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.token}</td>
                          <td className="pok">{item.name}</td>
                          <td className="pok">{item.date}</td>
                          <td>
                            <img
                              src={edit}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              /* onClick={() => listDelete(id)} */
                            />
                       
                           <img
                              src={del}
                              height="30px"
                              width="30px"
                              style={{ cursor: "pointer" }}
                              onClick={() => listDelete(id)}
                              className="mr-3"
                            />
                          </td>

                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </body>

      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
