import React, { useEffect, useState } from "react";
import "../Header/haeder.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";

export const Header = () => {
  const location = useLocation();
  const nav = useNavigate();
  const title = location.state.title;
  const name = location.state.name;
  const userId = location.state.userId;

  const gotopage = (title, name) => {
    nav("/Overview", { state: { title, name,userId } });
  };

  const backToMain = () => {
    nav("/Main", { state: { title, name,userId } });
  };

  return (
    <>
      <div class="profile44">Profile</div>

      <div class="swatch-parent60" onClick={() => gotopage(title, name,userId)}>
        <img alt="" src="/img/Group 5551.png" />
      </div>
      <div class="swatch-parent62" onClick={backToMain}>
        <img alt="" src="/img/Group 5550.png" />
      </div>
      <div class="swatch-parent63">
        <div class="swatch101"></div>
        <img class="person-icon22" alt="" src="/img/person.svg" />
      </div>
    </>
  );
};
