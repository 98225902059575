import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import eye from "../asset/img/eye.png";
import eye1 from "../asset/img/eye1.png";
import Modal from "react-bootstrap/Modal";
import { ECLTPR } from "./ExcelUser";
import { ExcelUserQues } from "./ExcelUserQues";
import { ExcelUserAnswer } from "./ExcelUserAnswer";

export const ReportUser = () => {
    
  const [mainList, setMainList] = useState([]);
  const nav = useNavigate();

  const fileName = "گزارش کاربران";
  const filename1 = "گزارش آزمون";
  const filename2 = "گزارش نتایج آزمون";

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch("https://drp.academyxray.com/panelAdmin/userlist");
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, [mainList]);

  const [showX, setShowX] = useState(false);
  const handleCloseX = () => setShowX(false);
  const handleShowX = () => setShowX(true);

  const [Ques, setQues] = useState([]);

  const showAnswer = async(id,token) => {
    const res = await fetch(`https://drp.academyxray.com/panelAdmin/findeUserAnsewr/${id}`);
    setQues(await res.json());
    handleShowX(true)
  }
  
  const [showY, setShowY] = useState(false);
  const handleCloseY = () => setShowY(false);
  const handleShowY = () => setShowY(true);


  const [resultQ, setresultQ] = useState([]);

  const showResult = async(id,token) => {
    const res = await fetch(`https://drp.academyxray.com/panelAdmin/findeUserResultQ/${id}`);
    setresultQ(await res.json());
    handleShowY(true)
  }

  const [search,setSearch] = useState('')
 
  return (
    <>
    <body className="hold-transition sidebar-mini">
    <div className="wrapper">
        <div className="content-wrapper">
        <div className="content-header">

          <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="pok">ردیف</th>
                      <th className="pok">کد یکتا</th>
                      <th className="pok">کاربر</th>
                      <th className="pok">تاریخ عضویت</th>
                    </tr>
                  </thead>
                  {mainList.map((item, index) => {
                    const { id, name, token,date} = item;
                    return (
                      <tbody
                        class="table-group-divider table-divider-color"
                        key={item.id}
                      >
                        <tr>
                          <td className="pok">{index + 1}</td>
                          <td className="pok">{item.token}</td>
                          <td className="pok">{item.name}</td>
                          <td className="pok">{item.date}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
    </div>
    </div>
    </div>
    </body>

    
      <Modal show={showX} onHide={handleCloseX} size="lg"  centered style={{marginRight:'100px',marginTop:"10px"}}>
      <Modal.Body>
      <div className="row">
        <label className="m-1">جستجو براساس کد آزمون / تاریخ آزمون / دسته سوال : </label>
        <input type="text" maxLength={10} className="form-control col col-sm-3 m-1 text-center mr-2" onChange={(e) => setSearch(e.target.value)} />
        <ExcelUserQues apiData={Ques} fileName={filename1} />
        </div>
       <hr />
      <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>ردیف</th>
                    <th>کد آزمون</th>
                    <th>سوال</th>
                    <th>دسته سوال</th>
                    <th>پاسخ درست</th>
                    <th>پاسخ داده</th>
                    <th>تاریخ آزمون</th>
                    <th>مدت زمان</th>
                  </tr>
                </thead>
                {Ques.filter((val) => {
                            if (search == "") {
                              return val;
                            } else if (
                              val.token
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            } else if (
                              val.date
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            }else if (
                              val.grid
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                .map((item, index) => {
                    const { id, title, img } = item;
                  return (
                    <tbody
                      class="table-group-divider table-divider-color"
                      key={item.id}
                    >
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.token}</td>
                        <td>{item.ques}</td>
                        <td>{item.grid}</td>
                        <td>{item.ans_true.replace("ques","")}</td>
                        <td>{item.ans_false.replace("ques","")}</td>
                        <td>{item.date}</td>
                        <td>{item.times}ثانیه</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
      </Modal>

      <Modal show={showY} onHide={handleCloseY} size="lg"  centered style={{marginRight:'100px',marginTop:"10px"}}>
      <Modal.Body>
        <div className="row">
        <label className="m-1">جستجو براساس کد آزمون / تاریخ آزمون : </label>
        <input type="text" maxLength={10} className="form-control col col-sm-3 m-1 text-center mr-2" onChange={(e) => setSearch(e.target.value)} />
        <ExcelUserAnswer apiData={resultQ} fileName={filename2} />
        </div>
        <hr />
      <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>ردیف</th>
                    <th>کد آزمون</th>
                    <th>آزمون</th>
                    <th>تعداد سوال</th>
                    <th>پاسخ صحیح</th>
                    <th>پاسخ غلط</th>
                    <th>تاریخ آزمون</th>
                  </tr>
                </thead>
                {resultQ
                          .filter((val) => {
                            if (search == "") {
                              return val;
                            } else if (
                              val.token
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            } else if (
                              val.date
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            ) {
                              return val;
                            }
                          })
             .map((item,index) => {
                  return (
                    <tbody
                      class="table-group-divider table-divider-color"
                      key={item.id}
                    >
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.token}</td>
                        <td>{item.onv}</td>
                        <td>{item.num}</td>
                        <td>{item.trueT}</td>
                        <td>{item.falseF}</td>
                        <td>{item.date}</td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
      </Modal>

      </>
  )
}
