import React, { useEffect, useState } from "react";
import "../List/list.css";
import "../List/global.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../Header/Header";
import axios from 'axios'
import moment from "jalali-moment";


export const List = () => {
  const location = useLocation();
  const nav = useNavigate();

  const name = location.state.name;
  const onv = location.state.onv;
  const gru = location.state.gru;
  const title = location.state.title;
  const userId = location.state.userId;
  
  const [mainList, setMainList] = useState([]);

  useEffect(() => {
    const getAllProductsRT = async () => {
      const res = await fetch(
        `https://drp.academyxray.com/panelAdmin/searchItemMainlist/${gru}`
      );
      setMainList(await res.json());
    };
    getAllProductsRT();
  }, []);

  const gotopage = async(name, onv, gru, title,id) => {
/*     await axios.put(`https://drp.academyxray.com/panelAdmin/updateAl/${id}`,{
      type:'1'
    }) */
      await axios.post("https://drp.academyxray.com/panelAdmin/saveListQues",{
        lione:name,
        litwo:onv,
        litree:gru,
        lifour:title,
        token:userId,
        date: moment(new Date(), "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"),
        stat:'1'
      })

    nav("/Toggle", { state: { name, onv, gru, title,userId } });
  };

  const gotopageU = (name, onv) => {
    nav("/Overview", { state: { name, onv,userId } });
  };

  const gotopageB = (name, onv) => {
    nav("/Mainto", { state: { name, onv,userId } });
  };

  return (
    <>
      <div class="profile4" hidden>{gru}</div>
      <div class="profile4kl">Dental Panoramic
          <p style={{textAlign:'center'}}>R A D I O L O G Y</p>
      </div>
      <div className="lista" dir="ltr">
        <div className="swatch6a" />
        <span className="po3" style={{color:'white'}}>{gru}</span>
        <div className="section-1a">
        
        <p></p>
          {mainList.map((item) => {
            const { id, title, maintow_id, main_id } = item;
      return (
          <>
            <div
                  className="row-inseta"
                  onClick={() => gotopage(name, onv, gru, title,id)}
                >
                  <div className="edit-buttona">
                    <div className="edit-buttona">
                      <div className="diva">􀆅</div>
                    </div>
                  </div>
                  <div className="imagea">
                    <img
                      className="app-iconsa"
                      alt=""
                      src="/img/app-icons@2x.png"
                    />
                  </div>
                  <div className="titlea" style={{ fontSize: "15px" }}>
                    {item.title}
                  </div>
                  <div className="trailing-accessoriesa">
                    <div style={{ marginTop: "20px" }} className="disclosure">
                      {" "}
                      <img alt="" src="/img/vuesaxoutlinearrowright.svg" />
                    </div>
                  </div>
                  <div className="grabbera">
                    <div className="grabber1a">􀌇</div>
                  </div>
                </div>
                
              </>
            );
          })}
        </div>
      </div>


      <a class="swatch-parent60" onClick={() => gotopageU(name, onv)}>
        <img alt="" src="/img/Group 5551.png" />
      </a>
      <a class="swatch-parent62" onClick={() => gotopageB(name, onv)}>
        <img alt="" src="/img/Group 5550.png" />
      </a>
      <div class="swatch-parent63">
        <div class="swatch101"></div>
        <img class="person-icon22" alt="" src="/img/person.svg" />
      </div>
    </>
  );
};
