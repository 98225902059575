import React, { useEffect, useState } from "react";
import "../HeaderMain/ahaeder.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const Header = () => {
  const nav = useNavigate();
  const location = useLocation();
  const title = location.state.title;
  const name = location.state.name;
  const userId = location.state.userId;
  
  const gotopage2 = (title, name) => {
    /*  nav('/Overview',{state:{title,name}}); */
  };

  const backToMain = () => {
    /* nav('/Toggle',{state:{name}}); */
  };

  const [showBR14, setShowBR14] = useState(false);
  const handleCloseBR14 = () => setShowBR14(false);
  const handleShowBR14 = () => setShowBR14(true);

  const showHandelpopsocial = () => {
    handleShowBR14(true);
  };

  const hidePop = () => {
    
    handleCloseBR14(true);
  };

  const gotopage = (title) => {
    nav("/ExitDeveloper");
  };

  return (
    <>

      <div class="profile4kl">Dental Panoramic
          <p style={{textAlign:'center'}}>R A D I O L O G Y</p>
      </div>
      
      <a class="swatch-parent60KL" onClick={() => gotopage2(title, name)}>
        <img alt="" src="/img/Group 5551.png" />
      </a>

      <a class="swatch-parent62KL" onClick={showHandelpopsocial}>
        <img alt="" src="/img/ext.png" />
      </a>
      <div class="swatch-parent63KL">
        <div class="swatch101KL"></div>
        <img class="person-icon22KL" alt="" src="/img/person.svg" />
      </div>

      <Modal show={showBR14} onHide={handleCloseBR14}>
        <div class="pop-up-exitK" dir="ltr">
          <div class="swatch-parent19K">
            <div class="swatch48K"></div>
            <div class="do-you-wantK" style={{ color: "white" }}>
              Do you want to exit ?
            </div>
            <div class="sign-in-btn30K">
              <div class="button-text30K mr-1" onClick={hidePop}>
                <div class="falseK" style={{ color: "white" }}>
                  Cancel
                </div>
              </div>
              <div class="button-text31K mr-1" onClick={gotopage}>
                <div class="falseK" style={{ color: "white" }}>
                  Ok
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
